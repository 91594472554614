import '../style/App.css';
import '../components/article/ReferenceBox';
import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function Earthnauts() {

    return (
        <div className="article-content">
            <p className="article-content">
                I'm intrigued by this recurring theme I've noticed in human systems that change is something to be appreciated. Not on the macro dose; on the whole, I find people to be mostly averse to change. But on the small dose, walking to a familiar place via an unfamiliar route, taking a short vacation to get out of the rut of your day-to-day, trying a new restaurant, people are generally in favor. There's an inherent human discipline, in wanting to experience something different. Most of us would agree that a life in which every day looks the same as the day before would be one hardly worth any excitement or flavor. Hence, words like mundane, rote, droll, are in our vernacular. We crave that differential, between the known and the unexplored. It gives us a sense of satisfaction, to be somewhere we've never been, which is an inherent reward that leads me to highlight that which distinguishes humans above many other species.
            </p>
            <p className="article-content">
                Humans have a particularly long requisite maturation period <InlineLink reference={new ReferenceLink("when compared with other animals", "https://en.wikipedia.org/wiki/Parental_care")}/>. By this, I mean, the time required for a parent to be with their child, before that child is ready to navigate the world on their own, is extended, relative to other earth-bound species. Humans are what we call an altricial species, which means that we are under-prepared to survive in the world at birth; we have to spend some time under the care of one or more parents before we can be independent. Given parental care is <InlineLink reference={new ReferenceLink("an extremely costly affair (to the parent)", "https://www.britannica.com/topic/animal-social-behaviour/Social-interactions-involving-the-costs-and-benefits-of-parental-care")}/>, this is a significant differentiating factor between humans and other animals. We devote a huge portion of our finite energy to rearing our young. 
            </p>
            <p className="article-content">
                And during a young one's life, we spend outsized resources on learning & development. Especially as the society we live in today is structured, learning is a forced activity a young one will undertake until 18 years of age, with a heavily-recommended additional 4 years. Then there are many subsets of people who continue in classrooms into another decade after that. Imagining a life with an expectancy of 80 years, we've taken for granted that around 20 years or so of that life would go towards education, which we deem necessary (but not necessarily sufficient)*. There are all methods of activities people suggest to further accelerate their capability to earn standing in this world, but learning, training, and education are cornerstones. But even these are the layers society bakes on top of an inherent desire that the young have to explore. They are bewildered, curious, entertained, by the simplest facets of life at a young age.
            </p>            
            <p className="article-content">
                Humans spend a huge chunk of our lives in learning and exploration phases, under the safety net of parental supervision. We are given full scope to play, try, test, with the assurance that a parent can take care of all of the necessities required to keep our hearts beating. This is truly the time in which we are the most free, mentally. We have intense physical and biological restraints, but we are unencumbered by the decades of information that is about to invade our brains. This is the one and only time each of us truly exists. Of course, there's a fair degree of precomputed context inserted in us via the schematic of our genetic composition, and we're never freed from that. But by and far, as a species, we are given the most freedom in terms of mapping our own brains, preferences, and predilections, during early childhood and teenage years. After that, the sheer amount of context established is quite large, and mostly the fundamental components of a person's personality will be settled, but there is still some room for adaptation. 
            </p>
            <p className="article-content">
                This is a key differentiating factor; how much of our selves are pre-encoded vs. malleable? Generation to generation, humans are well-equipped to adapt to a changing environment. It's why we are always able to say 'kids these days' and shake our heads at the changing of times and modernity. It's because our kids, the next generation, will always have the capability to turn out slightly different than we ourselves did! That's the beauty of it. That's our natural advantage. Just enough preloaded context to get started, and then intense support to get ready to meet the next set of changes in the world. Our societies move fast. We're all some kind of earthnauts, exploring this planet and little by little, finding ways to make it more humane, more efficient, more reliable. Incentive structures of society on the macro scale and the micro scale are not always (read: never) aligned, but somewhere in the local optima, good things emerge and we continue to progress. Coordination is very, very difficult amongst any group, let alone a group that spans 6 continents and does not share a common language. But in my whatever, trans-humanist, techno-futurist lens of things, I see the path to getting there.
            </p>
            <p className="article-content">
                Just to put this to context, let it sink in for a moment that mature human civilization has been active for a span of less than 10<sup>4</sup> years. If we look just at the advancement of the modern homo sapiens, that is more approximately a 10<sup>5</sup> year affair. Now compare that against the fact that dinosaurs, the original earthly apex predators, roamed this planet for a span of 10<sup>8</sup> years! That is a full 3 orders of magnitude longer. What will we accomplish if we make it that long? What will those societies look like? And let's say our species also ends up hanging out for some 10<sup>8</sup> earth rotations around the sun before expiration; that means we have literally completed 0.1% of the lifespan of the human race. 99.9% more to go.
            </p>
            <p className="article-content">
                So, I hypothesize that those little feelings of need for change are borne of a deep-rooted need to learn, explore, and adapt to the environment we are in. We are bodies and brains tuned towards learning. Do not stymie those impulses! Let your brain wander free, old and young. Of course, as you age, you'll tends towards reaping the spoils of everything you've learned through time. In accordance with <InlineLink reference={new ReferenceLink("tradeoffs between exploration and exploitation", "https://www.nature.com/articles/npp2017108")} />, it does not always pay off to explore, and there are external factors that will guide this decision, but I do think the urge is in all of us. Feed your curiosity 🙌🏾️.
            </p>
            <p className="article-content footnote">
                * I do think people today slightly misappropriate these early learning years. I attended a four-year university, but I think I could have accelerated my learning faster if I had been in a more application-oriented environment, rather than an academic one. Different modes suit different people, and in a word of optionality, sometimes we err towards that which minimizes our sharper edges and allows us to blend into the fabric of our society. But, this topic (Is a College Education Worthwhile?) should be explored separately.
            </p>
        </div>
    );
}

export default Earthnauts;
