import '../style/App.css';

import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function DecisionMaking() {
	return (
	<div className="article-content">
        <p className="article-content">
            Decision-making is notoriously painful. In a world of increasing freedom, discerning truth and increasing efficacy has not gotten easier. We need to come up with better systems for de-biasing our priors, assessing relevant information, and measuring outcomes after execution of a decision. This framework can apply to both professional and personal life. The ultimate goal here is to think of systemic ways in which we can improve our thinking capability.
	    </p>
        <h2 className="article-content">
            Decision-Making Pitfalls
        </h2>
        <p className="article-content">
            Power is concentrated in the hand of decision-makers. Regardless of how close they are to the effects of their decisions, select people are entrusted to guide and influence. This is despite the fact they may do so with insufficiently-elaborate topologies and little observability into their results. None of us is a super-sentient being capable of knowing all that there is to know. We are loaded with biases, hidden and seen, which affect our ability to evaluate the world clearly and rationally. But the stakes are simply higher, the more power a person has.
        </p>
        <p className="article-content">
            Some of you are making large impact decisions, no doubt. Many of you may think you are making inconsequential decisions, like which cucumber to buy, or which programming language to use in a data pipeline, but the reality is the effects of even those small decisions can resound throughout a system. But humans as a collective, we are not using enough of the tools at our disposal to make better decisions. We are still working in ad-hoc, reactionary frameworks that are leading to suboptimal outcomes.
        </p>
        <p className="article-content">
            So, why is it difficult to make better decisions?
        </p>
        <h3 className="article-content">
            Information Siloes
        </h3>
        <p className="article-content">
            We exist in cities, but we live in villages. In an age of excess information, our tribalism is ratcheting up higher, across geospatial boundaries. We surround ourselves with people who agree with us, and we tend to deride those who think differently.
        </p>
        <p className="article-content">
            Many of us travel in siloes, where we saliently receive information that is constantly reinforcing our existing point of view. Unbeknownst to us, we are getting further enmeshed into a manufactured reality built to fit our preconceived notions. Information served in the medium of hyper-personalized algorithms have set the tone for this environment. This is extremely dangerous, as it constructs hidden biases that obscure reality. One must actively resist to avoid getting pulled into the clutches of reinforcement thinking.
        </p>
        <p className="article-content">
            A useful thought experiment is <InlineLink reference={new ReferenceLink("Plato's Allegory of the Cave", "https://en.wikipedia.org/wiki/Allegory_of_the_cave")}/>. In that one, you imagine yourself as a caveman whose only visual input is the light of a campfire dancing on the walls, who is ignorant of the outside world. Your worldview is comprised of these shapes and shadows which only tell a fraction of the story. Much like this, the algorithms and the social siloes we exist in become like a self-filtering device that expose us only to the information that can get through those spaces. They keep reinforcing our version of the truth. And on we go, unaware of the overground reality.
        </p>
        <p className="article-content">
            So we have to periodically prune our brain of knowledge, and plant new seeds.
        </p>
        <h2 className="article-content">
            Solutions
        </h2>
        <h3 className="article-content">
            Epistemology
        </h3>
        <p className="article-content">
            We have to come from first principles of <InlineLink reference={new ReferenceLink("epistemology", "https://plato.stanford.edu/entries/epistemology/")}/>. This field of study covers the human impetus to understand what it is to know something. We should seek knowledge, seek contrary opinions, and seek understanding. When embarking on a project, sniff out prior art, because history is doomed to repeat itself. We should also be aware of the limitations of our knowledge.
        </p>
        <p className="article-content">
            You want to make sure that you're contextualizing reality with evidence. As programmers, the way to think about this is to ask yourself "What can I assert about reality?" It won't always match up with what you'd like it to be, and we must be vigilant of biases. We want to live in a world of maximal cognitive success, meaning that the things which we hold to be true hold factual representation in the world. We go about this by questioning our understanding, and being in pursuit of knowledge.
        </p>
        <p className="article-content">
            Sometimes, we engage in <InlineLink reference={new ReferenceLink("fallacious, illogical thinking", "https://yourlogicalfallacyis.com/")}/>. Once we recognize the signs of fallacy, we should be better prepared to address it.
        </p>
        <p className="article-content">
            Expose yourself to contrarian thinking! If you're religious, read articles by atheists. If you're liberal, read news from conservatives. Expose yourself to the other side to actively de-radicalize your position and understand the counterfactual.
        </p>
        <h3 className="article-content">
            Randomization
        </h3>
        <p className="article-content">
            This is the fun one, I think. This is mostly hypothesis, but I think it's an experiment worth trying. I think that if we expose ourselves to more information that is completely outside the purview of what we would normally consider (via randomization), we might elevate our capability to make decisions. Randomized exposure to information will expand our knowledge base by exposing us to data that we would not normally come across. This can extend to increasing your interdisciplinary exposure, going to unexpected cities, reading books you've never heard of.
        </p>
        <p className="article-content">
            In calculated randomization of decisions, we can decrease our cognitive load, and increase our exposure to hidden biases and fallacies. The problem with siloes is that they obscure unknowns. The counterfactual is nowhere near your realm of consideration. Randomization can help draw it out to the forefront.
        </p>
        <p className="article-content">
            I've built a pretty basic tool called <InlineLink reference={new ReferenceLink("Meandering Fellow", "https://meandering-fellow.vercel.app/")}/> that offers location-based random suggestions. It's minimal, but it's a good starting point in this thought experiment. The code is all open-source, so feel free to send suggestions my way.
        </p>
        <h3 className='article-content'>
            Remain Data-Driven
        </h3>
        <p className="article-content">
            Going back to the study of knowledge, data is our modern schematic in passing quantifiable knowledge, typically in a mathematical or computer-readable format. This helps us to level up on our decision-making, because the field of known information has exponentially expanded in a way where we can have years of effort in minutes and build atop the collective efforts of humanity. With trust in data, we can significantly level up the fidelity of our inferences.
        </p>
        <p className="article-content">
            There is a great opportunity in today's society to replicate the principles of data-driven decision-making in virgin applications. Often, many of the decisions being made that trickle down into our lives, be it from family members, politicians, or bosses, are made through the lens of sentiment, rather than analysis. Feelings are a subcomponent to the decision-making process, and they should not be dismissed, but we should try and quantify them. Facts should always come before feelings in the decision-making process.
        </p>
        <h3 className='article-content'>
            Make Quantitative Predictions
        </h3>
        <p className="article-content">
            In making a decision, assess what all of the possible outcomes are. For every decision you make, think of the counter example of what other possibilities there are. Present the counterfactual to one hypothesis.
        </p>
        <p className="article-content">
            As you're going through the assessment of determining the outcomes, you should assign probabilities to the likelihood of different situations arising. Assign probabilistic weights to each of the final states. This is the best way you will have to retroactively analyze how effectively you are predicting outcomes. As you iterate on this ability, it will influence your confidence estimates on your own prediction capability.
        </p>
        <p className="article-content">
            Where systems are numerical, such as those in economics, we can use simulations to predict how final states might look. In non-numerical systems, you should still run through the possibilities to assess what sorts of final states may arise, and what other events they may trigger.
        </p>
        <p className="article-content">
            Lastly, assess the historical data. In the world of finance, we like to say that Past performance is no guarantee of future results. It's a money person's way of covering themselves in case things go wrong, but of course it's true. 
        </p>
        <p className='article-content'>
            When we're confronted with a decision, we should look to prior examples of when people have made similar decisions, and what those outcomes have been. Naturally, no two systems are entirely alike, but historical data is still the cheapest way to gather high fidelity information of how a particular decision turns out. 
        </p>
        <p className='article-content'>
            I've worked on a product called <InlineLink reference={new ReferenceLink("Rorqual", "https://rorqual.haletic.com/")} />, which is a social platform for forecasting. It's similar to other products, such as <InlineLink reference={new ReferenceLink("Metaculus", "https://www.metaculus.com/questions/")} /> and <InlineLink reference={new ReferenceLink("Good Judgment Open", "https://www.gjopen.com/")} />, except that our goal was to provide a format that encouraged interaction amongst social groups to quantitatively improve one's predictions capability. The product isn't yet done, but that's the ambition.
        </p>
        <h2 className='article-content'>
            Life-Cycle of a Decision
        </h2>
        <p className='article-content'>
            <ol className='article-content'>
                <li className='article-content'>
                    Gather knowledge. Question base assumptions and introduce yourself to contrary opinions. Utilize randomization to influence your knowledge base.
                </li>
                <li className='article-content'>
                    Find historical data reflective of the problem at hand which can be used to further quantify it.
                </li>
                <li className='article-content'>
                    Assess all possible outcomes in the decision tree.
                </li>
                <li className='article-content'>
                    Lock in the decision that maximizes expected benefit.
                </li>
                <li className='article-content'>
                    Once the decisions are made, analyze how effectively you were able to model the system and iterate on the process.
                </li>
            </ol>
        </p>
        <h2 className='article-content'>
            Try it out!
        </h2>
        <p className='article-content'>
            Knowledge can be a moving target. It's nice to live in a mathematical world that can be neatly categorized, but that is not our world. Human systems are messy, and we can do better to make them less so. When evaluating how to make decisions, we must question our base assumptions, gather all relevant data, and make confident predictions based on simulation and historical data. Once a decision is made, measure it, quantify the outcomes, and assess how accurate your prediction capability was in order to iterate on this process. 
        </p>
        <br/>
        <p className="article-content footnote">
            Some of the concepts in this article and both of the projects I've mentioned were developed in collaboration with <InlineLink reference={new ReferenceLink("Debanjum", "https://taksati.xyz/")}/>. Thanks, friend!
        </p>
    </div>
  );
}

export default DecisionMaking;