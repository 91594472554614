import './style/App.css';

function About() {
    return (
        <div className="about">
            <p>
            hi 👋🏾, i'm saba imran. just a person with some thoughts, a tiny blip in this grand universe. i'm here mostly with a bunch of questions and thoughts, but not as many judgments. you can find me here:
            </p>
            <a className="inline-ref" rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/sabaimran/">linkedin</a> and 
            <a className="inline-ref" rel="noreferrer" target="_blank" href="https://twitter.com/sabaimran_go">twitter</a>

        </div>
  );
}

export default About;
