import '../style/App.css';
import '../components/article/ReferenceBox';
import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function OdeToProgramming() {

    return (
        <div className="article-content">
            <p className="article-content">
                I really love being a developer. In the cosmic search for purpose, it gives me a sense of curiosity, self-efficacy, and understanding. I don't know if it's my <i className="article-content">raison d'etre</i>, but it's sure as heck a really fun way to spend my brief, transient time on earth.
            </p>
            <p className="article-content">
                I feel that people who don't code, or who aren't in tech, don't truly understand what I mean when I say that software <b className="article-content">scales</b> impact. I can write tens or thousands of lines of code, and have those deployed globally within a matter of minutes (for better or worse). People around the world can interact with automated software that I am writing and designing, and I can continue experiencing the rewards of that positive impact for time to come, generating impact-measured ROI long after that code first came to imagination.
            </p>
            <p className="article-content">
                And when I'm creating software, I'm building on the innumerable works of others. I wonder at the human ability to constantly iterate on the ideas and products that each of us builds. This applies to any collaborative industry (so, all of them?), but programming takes it to a much faster scale. Whenever any of us is writing code, we are utilizing a suite of technologies that were perfected and refined through prior art and development. And where shortfalls and imperfections exist, we can then be the ones who improve those technologies for the benefits of others. Or more likely, they just exist and people build entire products around workarounds, haha. But still! The code I write is built on top of a framework someone else developed, which is built using a language someone else developed, which is compiled into byte code to be read by a processor, which someone else developed. All development is a composite of really intricate tools working together.
            </p>
            <p className="article-content">
                The early software community pioneered movements to make software open and free to use, and this has been a paradigm shift to industry. This has democratized usage of digital products for all. <InlineLink reference={new ReferenceLink("Open source software", "https://en.wikipedia.org/wiki/Open-source_software")}/> is a major reason that technology moves so fast today; there are people who build insanely useful technology (that acts as a critical building block to other products), and they release it with a license which allows anyone to use it, free of cost. 
            </p>
            <p className="article-content">
                It's simply beautiful. So many prior industries have been built around gate-keeping and privatizing useful products and ideas under the guise of intellectual property and competitive secrecy. This stalls the development of humanity. Characters like <InlineLink reference={new ReferenceLink("Guido von Rossum", "https://en.wikipedia.org/wiki/Guido_van_Rossum")} /> (founder of <InlineLink reference={new ReferenceLink("Python", "https://www.python.org/")} />) or <InlineLink reference={new ReferenceLink("Roy Fielding", "https://en.wikipedia.org/wiki/Roy_Fielding")} /> (one of the founders of the <InlineLink reference={new ReferenceLink("Apache Software Foundation", "https://en.wikipedia.org/wiki/The_Apache_Software_Foundation")} />) or <InlineLink reference={new ReferenceLink("Linus Trovalds", "https://en.wikipedia.org/wiki/Linus_Torvalds")}/> (founder of <InlineLink reference={new ReferenceLink("Linux", "https://www.linux.org/")}/>) have a thoroughly outsized impact on society, just based on the sheer number of other people who build on top of their technology. It's like they're giving construction companies free bricks, free foundations, to build and renovate society's homes. There are other men and women who may have made more money, but these founders of free software have made a gargantuan impact. In the end, everything is temporary, and money comes and goes. However, systematic changes continue to have compounding effects.
            </p>
            <p className="article-content">
                Stemming from there, this culture of free, open, democratized access has made it really easy to learn how to code without any professional background. There are countless resources online to help train you in learning the basic fundamentals, as well as get into more niche topics as you advance, like machine learning or distributed systems. I really think that a dedicated soul need not go to college or even a boot-camp to learn how to develop software.
            </p>
            <p className="article-content">
                Of course, not all software could or should be free necessarily. Companies and individuals investing resources in building useful, targeted products do need to ensure their sustainable existence. And of course, there is still plenty of secrecy and gate-keeping in the software industry even today. Different products, teams, sub-industries, security concerns, will evolve with different operational paradigms. But nevertheless, the bedrock of open and free software supports the whole ecosystem. Especially in a world where drug companies will charge $500 for an inhaler, these software founders have set a remarkable precedent.
            </p>
            <p className="article-content">
                The process of building products with others is loads of fun. I'm sure this is true for any activity that is predicated on teamwork, but there's something inspiring in working with people who motivate and encourage me, as we work towards building something that we believe will be of use to others. We can riff off of each other's ideas, build on each other's code, and develop new features in our mental playground that will come to life. It's not always roses and butterflies, working with other people, but it's so rewarding when I hit a flow state with my team. There was a quote in my former office that read: 
            </p>
            <blockquote className="article-content">
                If you want to go fast, go alone. If you want to go far, go together. - <InlineLink reference={new ReferenceLink("unknown origin", "https://andrewwhitby.com/2020/12/25/if-you-want-to-go-fast/")} />
            </blockquote>
            <p className="article-content">
                It's stuck with me since the first time I saw it, because of the gravity of the statement. No one of us can build everything by ourselves. When we learn to work effectively with others, that is when our impact scales.
            </p>
            <p className="article-content">
                In the same thrill of building, the development cycles can be addicting. It derives from this fact of how easy it can be to test/deploy a change. This axiom highly depends on the nature of the work one is doing, but generally speaking, you can run complex ML models, deploy custom websites, test open source mobile apps, all from the comfort of your laptop and your home internet connection. It's crazy! I personally fall into an addictive state around these things, running away with experimenting and wanting to incrementally build just-one-more-thing. I compare this against woodworking, which I've done in the past. Building stuff with my own two hands, using machinery to construct useful, beautiful objects is rewarding. But, to explore this hobby as much as I'd like, I need to find a studio that has the equipment, because I'm only renting an apartment and I can't store professional woodworking gear in there. Writing code takes the cake on ease of setup.
            </p>
            <p className="article-content">
                <b className="article-content">
                    I think creation, at its core, is what gives people a sense of fulfillment and efficacy. It instills confidence in people, that they'll be able to manipulate the world, and undertake whatever tasks are presented in front of them. The more we build out our skills for accomplishing particular tasks, the more useful and effective we feel to ourselves, and also to society. When I exercise the skills to build, I am growing the muscle to solve the problems that I see in the world.
                </b>
            </p>
            <p className="article-content">
                And it still feels tangible. There's a kind of glorification of the suited business man, or the sweatshirt-wearing millionaire VC beloved, these CEOs and decision-makers of companies. These are the people who work in the high-level processes of making meta-level decisions on top of the work of others. This work is difficult. It's hard to quantify, it's hard to execute, and it's hard to motivate people. It also leads to a deluge of meetings, and in the end, it's always dependent on others executing the measurable results. From where I stand now, I don't see the appeal of that life. I relish the fact that I can make something with my hands, something that doesn't require words transmitted from one mouth to an ear, but rather words transmitted from my fingers to my laptop. Of course, I have to work with my dev team in this process, but it's more fluid than managerial, directional conversations. There's a sense of ownership and comfort, in actually building the end product.
            </p>
            <p className="article-content">
                In tangent to tangibility, programming improves the way I see the world. For one, I can interact with systems around me, and I can imagine how the underlying technology may have been built. It's a powerful feeling, this imagination that allows me to softly build out world systems, and mentally envision how they interact with each other. I can observe the way a page is surfacing data, and assess whether that may be a security risk. I can take a look at the speed of an button click, and imagine where optimizations could be improved in service calls. 
            </p>
            <p className="article-content">
                Another mechanism in which technology improves my worldview is through algorithms. Surprisingly, mapping real-life problems to an algorithmic space provides logical insights that reduce complexity into know solutions, or unsolved problems. For example, take the <InlineLink reference={new ReferenceLink("secretary problem", "https://en.wikipedia.org/wiki/Secretary_problem")}/>. The secretary problem reflects the difficulty of choosing the optimal candidate for some open position. If there are <code className="article-content">n</code> candidates in the applicant pool, then the algorithm suggests interviewing and automatically rejecting the first <code className="article-content">n/e</code> candidates (where <code className="article-content">e</code>  is <InlineLink reference={new ReferenceLink("Euler's number", "https://en.wikipedia.org/wiki/E_(mathematical_constant)")}/>), on the presupposition that the likelihood of the perfect candidate being in the first batch is low and that this evaluation process can be used to set the expectations for the next candidate. After the first <code className="article-content">n/e</code>, the next candidate to cross the bar for expectation would be hired. The original problem is framed in hiring of a secretary, but it can be mapped to marriage, housing, or anything that includes lots of options and limited selection.
            </p>
            <p className="article-content">
                Building an understanding of modern tech can also get you on the path of understanding experientially what is being built in the bleeding edge, how you can take it further, and what its potential is. You can be an early adopter or developer on those exciting new technologies. For example, you can play with, or build on top of, state-of-the-art ML models, robotic pets, or AR-friendly hardware. So cool! It feels a bit like, I'm already in the future before the rest of the world gets there. I have this vision of how I want or expect the future to look, and being a developer gives me this slight edge to be ahead of other people in experimenting with certain new tech. 
            </p>
            <p className="article-content">
                There's always going to be a learning curve. Just when I've understood something, there's another technology that comes and starts disrupting the prior one. Development moves fast, if you haven't caught that by now, and you always have to keep yourself a bit updated on what's happening outside of the silos that you're working in. This can be pretty fun, because I'm constantly learning new things, but I can see that it's also daunting to some people. It's not like being a doctor, where you spend a huge chunk of time learning the cannon, and then observe long periodic verification cycles to make sure you're still up to date. The software learning cycle is quick and speedy. That's why I think it's important to discern patterns in architectural decisions, to understand the why's and how's of best practices, but not to commit too heavily to any specific technology. It's also great practice to read developer documentations, like the <InlineLink reference={new ReferenceLink("Apache Kafka architecture document", "https://kafka.apache.org/20/documentation/streams/architecture")}/>, or architecture articles like <InlineLink reference={new ReferenceLink("LinkedIn's real time presence indicator", "https://engineering.linkedin.com/blog/2018/01/now-you-see-me--now-you-dont--linkedins-real-time-presence-platf")}/>. 
            </p>
            <p className="article-content">
                Specialization is a great way to differentiate yourself in the industry, if there's something that you're really passionate about, or you see a pressing need for, but this should be a conscious decision. Even if you're going down the specialist route, you should still be keeping up-to-date on the relevant changes happening within the field you're specializing in, as the world is always looking for a way to do things better.
            </p>
            <p className="article-content">
                Lastly, I'm enthralled by the way technology can be used to solve old problems using modern, scalable systems. I'm excited when I think of the applications in improving drug delivery, autonomous transportation, democratization of finance, and so many other areas. The potential is seemingly limitless, and feeling like I have even an inkling of capability to satisfy those important endeavors is really empowering.
            </p>
            <p className="article-content">
                Happy hacking, friends!
            </p>
        </div>
    );
}

export default OdeToProgramming;
