import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function AISafety() {
  return (
    <div className="article-content">
        <h2 className='article-content'>How we should think of regulation risk</h2>
        <p className="article-content">
            There's been a lot of discontent and discussion around the development of AI applications, and corresponding regulation, as of late. As people discuss regulatory options, I figured I'd outline my two cents.
        </p>
        <p className="article-content">
            It's important that AI development take place in the open, and freely, in order to protect against a few things:
            <ol className='article-content'>
                <li className='article-content'>
                    Monopoly control of critical foundation models is going to be severely detrimental to long term safety for humanity. We cannot trust a single corporation or a set of a few corporations to keep our best interests in mind, and open source development of AI models is necessary to protecting against this risk.
                </li>
                <li className='article-content'>
                    Bad actors are going to continue to develop models. Developing a model requires little more than electricity, GPUs and a dataset, and so the barrier to entry is far lower than it is for other dangerous technologies, like nuclear weapons. If we add red tape and regulation to model development and release, the only people who would be stopped are the ones with good intentions who follow the rules.
                </li>
                <li className='article-content'>
                    AI has significant potential in shaping the way that humanity operates, and it can specifically makes us safer and healthier. As we continue to explore how this tech can improve our lives as a collective, we need to be sure that development is kept open for novel, positive innovation.
                </li>
            </ol>
        </p>
        <h2 className='article-content'>We should remain skeptical</h2>
        <p className="article-content">
            Why are so many large players, like OpenAI and Microsoft, now calling for AI regulation? Because they've already developed large foundation models. They've got theirs, and now they want to establish a moat to box out competition. Hey, all is fair, and that's what you should expect of a business, but it's best not to be mistaken about the intent here.
        </p>
        <p className="article-content">
            AI should be regulated, but we need to be careful about where we apply these brakes. I want to see:
            <ol className='article-content'>
                <li className='article-content'>
                    more observability into the processes AI companies are using to train models
                </li>
                <li className='article-content'>
                    transparency around what data has gone into their training set, and how they're storing, anonymizing, and auditing it
                </li>
                <li className='article-content'>
                    greater protections for consumers and creators who've unwittingly had their data immortalized in models with attention spans exponentially larger than our own
                </li>
                <li className='article-content'>
                    companies being clear and transparent when consumers are engaging with an AI, rather than an actual human
                </li>
            </ol>
        </p>
        <p className="article-content">
            I had the chance to attend a fireside chat that YCombinator arranged with the FTC Chairwoman, <InlineLink reference={new ReferenceLink("Lina Khan", "https://en.wikipedia.org/wiki/Lina_Khan")}/>  on Friday. She discussed her thought patterns around preserving competition in AI, and understood the critical role that open source is going to have in fostering innovation. This is great. It's critical that regulatory bodies keep an open door to engage with private players who will be affected by these legislative decisions.
        </p>
        <p className="article-content">
            What's interesting to draw upon is that, compared to our classroom, textbook understanding of competition, the way anti-competitive practices look has changed in this age of technology juggernauts. Anti-competitive is retention of data moats, platform ownership, self-preference biases. Our lenses need to adapt.
        </p>
        <p className='article-content'>
            A negative outcome of this whole AI experiment would be if we were to develop AGI, and control of it came under the hands of few, private entities. You can rest assured that AI is going to be involved in every crevice of decision-making within our lifetime, from the classroom, to the court, to the precinct, to the home. Whoever controls that gets to control the way we think and how we make decisions. The future of AI needs to be open to put control back into the hands of individuals. We should not repeat the same mistakes we made when our social platforms became dominated by the few, in an ads-revenue based model.
        </p>
        <p className='article-content'>
            This is why it's so important that Khoj be open source. We want people to feel a level of comfort that our platform does what we say it does, and treats them as customers, not products.
        </p>
        <h2 className='article-content'>Sidenotes</h2>
        <p className='article-content'>
            As an aside, I find the speculation and concentration on AI development rather interesting. Humans have been talking a lot about AI safety, about how people are going to use AI to spread harm. These conversations, at least in my memory, had not extended generally to technology with as much intensity. We did not put tight restrictions and audits on computers, software, or services, at the risk that they might be used by bad actors, though they indubitably are.
        </p>
        <p className='article-content'>
            For much of my life, the internet and technology has been a wild playground of ideas and free speech unfettered and unencumbered. Operating Systems have been used by hackers, foreign governments, and malignant actors for uncouth purposes, but we've never talked about litigating for the slowing down of operating system development. Though one can imagine there are probably meetups for terrorists taking place on Zoom, or elsewhere on the internet. One can say that awareness of the underlying platform issues came at a ten year offset from when they were necessary.
        </p>
        <p className="article-content">
            In the latest round of development, AI has struck a chord in the imagination of those from DC to Silicon Valley, from Karachi to Tokyo. Is it because it's easily understood to mimic human cognition, and we are inherently skeptical of the uncanny mimicry of machines to humans? Is it because much of web2 technology was still poorly understood at the turn of the millennium, when the internet revolution was starting out? Is it because our government had a vested interested in the development of chips, internet, and the web? Is it the shift away from specialized models, and into general AI applications?
        </p>
        <p className="article-content">
            With all the risks and tradeoffs ahead of us, I genuinely don't think the greater risk is AI getting into the hands of bad actors, though that will happen. I think the greater risk is AI not getting into the hands of good actors who can use it to accelerate our progress as a species.
        </p>
    </div>
  );
}

export default AISafety;
