export class ReferenceLink {
    constructor(text, link) {
        this.text = text;
        this.link = link;
    }
}

export function InlineLink(props) {
    return(
        <a className="inline-ref" rel="noreferrer" target="_blank" href={props.reference.link}>{props.reference.text}</a>
    );
}

export function SupplementalReferenceBox(props) {
    const listItems = props.references.map((value, idx) =>  
        <li className="reference-link" key={idx} >
            <a  className="reference-link" rel="noreferrer" target="_blank" href={value.link}>{value.text}</a>
        </li>
    );

    return (
        <div className="reference-box">
            <p className="reference-box">
                Supplemental Reading:
            </p>
            <ul className="reference-box">
                {listItems}
            </ul>
        </div>
  );
}
