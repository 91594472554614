function CiudadDeBuenosAires() {
  return (
    <div className="article-content">
        <p className="article-content">
            I'm in Buenos Aires. Here, the sidewalks are orange flowers, the skies are a cacophony of blue before they become a cornucopia of yellow, purple, and orange. Amid towering structures of gray concrete and the drips of air conditioner, trees spring up relentlessly in the cracks and crevices of the city. They're given primacy. Carefully etched around the perimeter of a 6-story evergreen, habitations of men bow in respect. 
        </p>
        <p className="article-content">
            The airs are good here, fittingly to the name. The air is easy-going, hearty and warming. The air is full of the symphonies of football stars and the hymns of churches. The air carries the aroma of barely spiced foods and judiciously cooked steaks.
        </p>
        <p className="article-content">
            There's an experiment unfolding here in the medium of the blue dollar rate. An obvious opportunity for arbitrage seems to lie dormant, when you can buy 300 pesos for a dollar in the informal markets, and sell 150 pesos for a dollar in the formal markets. Laws of economics function as suggestions. 
        </p>
        <p className="article-content">
            I can't help but note the whiteness of the skin of the people on this land, independent since the 1800s of their Spanish colonizers. The indigenous faces and bodies are remarkably absent, relegated to 4% of the population. It occurs to me that the Americas are an example for when the Bad Guys™ won, committing mass genocide and other atrocities to no significant consequence. The world turns on, as kingmakers flesh continents and countries and the people are left to live in their carcasses.
        </p>
    </div>
  );
}

export default CiudadDeBuenosAires;
