import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function TheoryOfReligion() {
  return (
    <div className="article-content">
		<h1 className="article-content">The Beginning</h1>
        <p className="article-content">
            In the beginning, there was gaseous chaos, and then primordial soup. Life emerged despite the smallest of probabilities, though small probabilities on the universal scale do let you take an assumed stance that there exists some example of the improbable event.
        </p>
        <p className="article-content">
            550 million years ago, the <InlineLink reference={new ReferenceLink("Cambrian Explosion", "https://en.wikipedia.org/wiki/Cambrian_explosion")}/> led to the runaway proliferation of life on Planet Earth. All in all, it's been a wild ride on our rock-spaceship since then. The overwhelming majority of the life that has ever existed here has died out.
        </p>
        <p className="article-content">
            In precursors to <InlineLink reference={new ReferenceLink("our modern form", "https://humanorigins.si.edu/education/introduction-human-evolution") }/>, we see the development of attributes like limbs, eyesight, consciousness, emotions. These traits confer a selective advantage to their host organisms, finding themselves expressed repeatedly across several different species. But some little critters are completely alien, floating around sightlessly through the depths of the ocean. The planet is teeming with life, positively bursting at the seams with survival experiments.
        </p>
        <p className="article-content">
            Apes in particular forked off and expanded their processing power in the form of humans. We are subject to the limitations of those bodies, be it our brains, our desire for procreation, our aching joints. Love is a hormonal reaction taking place in your brain, in order to further the genetic motive to sustain existence. Why? Just because that's the attribute that survives.
        </p>
        <p className="article-content">
            Consciousness and intelligence are a byproduct to the gene's motive to replicate. We've evolved significantly more complex strategies to meet that end. Our ability to communicate with each other gives us an edge over other species. We are able to organize, transmit information. We are able to make tools, and share them amongst our kin to work more efficiently. We create even more complex systems of exchange through bartering and currency.
        </p>
        <h1 className="article-content">Search For Meaning</h1>
        <p className="article-content">
            Lots of gods have come and gone through the ages. There is no rational reason to believe in any of them, but they are fascinating to study. They give us a reflection of humanity itself, and the product of its mind. When we look at our gods, we see humanity staring back.
        </p>
        <p className="article-content">
            The Upper Paleolithic Transition (around 40000 BC) seems to mark the shift in the nature of humanity, from a species oriented around survival, to one that is starting to paint and make art. There's a significant advancement in its cognition; a recognition of time, patterns, and knowledge.
        </p>
        <p className="article-content">
            Early humans would sit in caves, and paint pictures on the walls of bison, lions, rhinoceroses. They were making sense of the world around them, slowly growing a lexicon in order to communicate with each other. Humans have always loved stories, and they have always searched for meaning. They have turned to the volcanoes, the Sun, shooting stars, to make sense of a world that is full of mysteries. They have <InlineLink reference={new ReferenceLink("drawn their fables", "https://en.wikipedia.org/wiki/Cave_of_Forgotten_Dreams")} /> on the walls of caves, told tall tales amongst the waning fire and dancing shadows on the cave walls, passed their legends orally through generations before they could afford to roll a papyrus and whet a quill.
        </p>
        <p className="article-content">
            It's no accident that one of the earliest and most ubiquitous gods throughout cultures is fire. Imagine yourself, an early human. You've found this ethereal substance which gives you warmth and beauty, but can also cause death and destruction. When you feed it, with logs or alms, it grows. When you do not nurture it, it dies. The world is simple. When you have fire, things are good. When you do not, things are not as good. In search for meaning, fire gives early people something satisfying. This theory doesn't stand up that well to time, when you realize that you can harness fire, and it does not have any perceivable sentience. It provides an easy counterfactual, for the fact that it depends on you. And naturally, as humans evolve and gain power, they want to feel that something more powerful than them deserves their worship.
        </p>
        <p className="article-content">
            Naturally, people turn to the heavens. People worship celestial gods, personified in the dark skies above. An interesting theme across many polytheistic religions I've noticed is that, when they have multiple gods who are attributed to different elements (wind, fire, earth), the sky god tends to be the top boss. Another interesting theme is that of <InlineLink reference={new ReferenceLink("seven heavens", "https://en.wikipedia.org/wiki/Seven_Heavens")} />, which was an allusion to the observance of the Mercury, Venus, Mars, Jupiter, Saturn, Neptune, and Uranus. Observers saw that these bodies traveled a bit differently in the night sky, and attributed a special significance to them, believing they orbited the Earth.
        </p>
        <p className="article-content">
            The fascinating aspect of religions is their permeability, osmotically passing through time and geographies, capturing the imaginations of their subjects. Weightless ideas, they present the most sublime drug as they nestle into the safe flesh of our minds and give solace of a higher power.
        </p>
        <p className="article-content">
            Another important factor to note here is that people had strong ideas about how societies should organize, though they did not always understand why they felt that way. For example, consider fasting. Before modern medicine, it might be hard for you to justify why people should willfully abstain from eating for hours, but some humans knew it made them feel good. The structures of religion gave people a safe body in which to derive ethics and provide post hot justifications. They were making sense of their internal states of morality and emotion, and formalizing them with the divine.
        </p>
        <h1 className="article-content">Comparative Religion (Case Study: Islam)</h1>
        <p className="article-content">
            Comparative Religion is a fascinating line of study that helps us understand how systems of religion evolve and interact with another across cultures. It allows us to envision religion as a framework of ideas for spirituality, as well as civic organization, at times. This invisible thread that weaves people together, in words and in the mind, is a powerful tool for understanding humanity.
        </p>
        <p className="article-content">
            On a personal note, I grew up in a Muslim household, so many of the stories that have piqued my interest are tangent to the Islamic worldview. Here are some interesting tidbits that highlight the interconnectedness of ideas and practices that are present in the Muslim faith:
            <ul className="article-content">
                <li className="article-content">
                    The story of man who builds a boat to save animals and people from a flood and lives to a long age is present in the <InlineLink reference={new ReferenceLink("Epic of Gilgamesh", "https://en.wikipedia.org/wiki/Gilgamesh_flood_myth")} />, an epic from Ancient Mesopotamia. This is analogous to the story of Nuh in Islam, same as Noah's Ark in the Bible.
                </li>
                <li className="article-content">
                    The story of a boy being carried down a river to safety during a time when boys are being killed due to a prophecy of one killing the king, is a story Hindus believe of <InlineLink reference={new ReferenceLink("Krishna", "http://mama.indstate.edu/users/swasati/birth_krishna.htm") }/>. This is analogous to the story of Musa in Islam, or Moses in Judaism.
                </li>
                <li className="article-content">
                    The belief in seven heavens and the original heavens being split was present in <InlineLink reference={new ReferenceLink("Ancient Mesopotamia", "https://en.wikipedia.org/wiki/Seven_Heavens#Mesopotamian_religion")} /> and a common belief amongst pre-Islamic pagans that carried over into Islam.
                </li>
                <li className="article-content">
                    The idea of Hell can be traced at least back to the Greeks, and possibly to the Zoroastrians before them. The idea was enveloped into the Christian tradition after the death of Jesus, as his disciplines were waiting for an apocalypse that never came, and subsequently searching for alternative explanations. I highly recommend listening to <InlineLink reference={new ReferenceLink("this podcast", "https://www.npr.org/2020/03/31/824479587/heaven-and-hell-are-not-what-jesus-preached-religion-scholar-says")} /> where former Evangelical & scholar Bart Ehrman lays out the origins of the Christian Hell.
                </li>
                <li className="article-content">
                    <InlineLink reference={new ReferenceLink("Arab Pagans", "https://www.khanacademy.org/humanities/ap-art-history/introduction-cultures-religions-apah/islam-apah/a/the-kaaba")}/> venerated the Kaaba and worshipped the Black Stone. They housed idols there and <InlineLink reference={new ReferenceLink("walked around the Kaaba seven times", "https://charterforcompassion.org/reza-aslan-no-god-but-god-the-origins-evolution-and-the-future-of-islam")}/>. This is strikingly similar to rituals that Muslims do for Ummrah and Hajj, minus idols in the Kaaba. Muslims today still revere the Black Stone.
                </li>
                <li className="article-content">
                    <InlineLink reference={new ReferenceLink("In Zoroastrianism", "https://raseef22.net/article/1069641-journeys-islamic-jewish-zoroastrian-babylonian-heavens")}/>, there is a story of the prophet Arda Viraf, who for one night ascends to the heavens, and is led by an angel to meet his god, Ahura Mazda, who shows him the souls of blessed individuals. Ahura Mazda then asks Viraf to be his messenger and bring his word to the people, a task he accepts. This story is nearly on the nose with the story of <InlineLink reference={new ReferenceLink("Isra and Miraj", "https://en.wikipedia.org/wiki/Isra_and_Mi%27raj")}/> in Islam. Ancient Zoroastrian customs also included praying to fire 5x a day based on solar and lunar patterns, cued by the sound of a bell (as a call to prayer). They even performed wudu. These are analogous to core practices in Islam.
                </li>
            </ul>
        </p>
        <p className="article-content">
            All of these stories predate Islam. Makkah was a hotspot for trade, and Muhammed was married to one of the wealthiest traders in the region. He made business trips on (his wife) Khadija's behalf. Moreover, the Arabs, pre-Islamic and post-Islamic, were lovers of stories. They loved artistry of words and venerated poetry, making them a prime bedrock for the inspiration from mystical tales. It's easy to weave together in the imagination how infectious ideas may have caught on.
        </p>
        <p className="article-content">
            Clearly, there are, and always have been, lots of theories and counter-theories in the mix. Religion has a flooded marketplace, and humans are eager consumers.
        </p>
        <h1 className="article-content">Abrahamic God - Memeticity</h1>
        <p className="article-content">
            As stated earlier, my specific upbringing was in a Muslim household, and therefore I have a particular understanding of the Abrahamic god, which unites the Middle Eastern religions of Judaism, Christianity, and Islam. Another reason in particular to discuss this god is that it has exhibited strong catchiness through the last two millennia, and I am curious to examine why that may be.
        </p>
        <p className="article-content">
            The Abrahamic god evolved with a particular appeal to memeticity. While prior gods provided more fantastical stories, they exhibited more human-like characteristics that made them fallible, and perhaps unworthy or worship. They also provided no real punishment away from believing in them. Plus, they would have had abundant counterfactuals (e.g., pray to the Sun god for a good harvest this year, and in reality proceed to have a bad harvest thereafter). As such, the narrative of mysterious ways and the further intangible nature of god has increased over the ages. In later stages, we find the Abrahamic god. It can't be seen, heard, touched. It has no evidence, and it's not obligated to answer your prayers or talk to you in any way. And people believe in it regardless! The memeticity of the god is further compounded by the fear of punishment should you dare not to believe in the god or disobey it. This makes it hard to leave and compels generational indoctrination. Simultaneously, it levies the promise of heaven to make you fond of its existence.
        </p>
        <p className="article-content">
            One may point to Hinduism, a religion that has been extant for several millennia and predates Judaism in age. My analysis on the matter is that Hinduism's endurance is due to its decentralized tolerance. If you ask any Hindu what in particular they believe, there is a lot of variation. This also presents an issue in the matter of conversion, as it is unclear what the individual would be converting into. In contrast, the Abrahamic religions are relatively stratified in their cannon. As mentioned earlier, polytheistic traditions are tolerant and absorbent by nature, and therefore widely open to interpretation and resistant to standardization.
        </p>
        <p className="article-content">
            The large slack afforded to tolerant religions may be a detriment to their memeticity. There may be a large volume of people in the net of the polytheistic tradition, but they will each have their own way of doing things, and the lack of cannon makes the ideas harder to sustain; there is no mandate to continue a specific tradition, and so each generation can innovate. This is a good characteristic for a tradition that wants to evolve with time and survive (Hinduism), but possibly a bad characteristic for a tradition that suggests objective moral and social righteousness or wants to amass numbers. Its non-federated structure makes it liable to dilution.
        </p>
        <p className="article-content">
            Of course, I cannot downplay the effect of colonialism and armed conflict in this matter either. The Christian and Muslim ideologies have benefitted from being backed by emperors and conquerors, allowing (and forcing) massive conversion to their numbers under the rule of non-secular government. Doubtlessly, this has contributed to the current masses who practice both of these religions.
        </p>
        <h1 className="article-content">Future Religions</h1>
        <p className="article-content">
            I'm an atheist, in the sense that I reject the traditional understanding of god. I leave room for exploration so as to ascertain the correct answer to what caused the universe to exist, and hope that our progeny come closer to the truth. The truth could be that we are one universe in a trillion such, that we are an experiment gone wrong, that we are in a deeply recursive simulation. Who knows! I'm sure that the question of the creator will only invite more puzzles. Beyond god, spirituality is the brain looking for meaning, and that is wonderful and beautiful and deserves attention.
        </p>
        <p className="article-content">
            I hope to see a future where Holy Wars are regarded as a thing of a barbaric past, in which it is unthinkable to lift a weapon to defend an unproven being and impose oppressive societal structures on those who are non-consenting. I hope to see people shed the zealous certainty of their beliefs, and recognize that the concept of god is unsettled.
        </p>
        <p className="article-content">
            I think the traditions, community, and structure that religions give to modern people are intensely valuable, and the secular world could learn a good deal from them about how to create better systems for organizing people. For example, the Bar Mitzvah is a meaningful transition into puberty for a Jewish man, the institution of Zakat is helpful for supporting Muslims in time of need, and Hindu customs around burial offer a structured methodology for grieving that eases the burden on the bereaved.
        </p>
        <p className="article-content">
            Contrast to secular analogies. Therapists are the new priests, meditation is the new prayer, Science is the new Explanation. The State is the new (church, synagogue, masjid, mandir, temple), and it is a woefully lacking substitute.
        </p>
        <p className="article-content">
            And the fact is that people will always search for meaning. Religion itself has ceased to offer enough to answer the questions that people today have, but that still leaves a void. What are we living for? What are our values? In this age of secularism, we have no less desire for strong foundations upon which we can stand. We have need for an <InlineLink reference={new ReferenceLink("Atheism 2.0", "https://www.youtube.com/watch?v=2Oe6HUgrRlQ")} />.
        </p>
        <p className="article-content">
            When I was younger, I often looked around me, at the structures of humanity, and I wondered what makes us special, much like my ancestors have in the past and my contemporaries do in the present. I've arrived at the conclusion that it's nothing in particular, really. There's nothing divine about us. We're just another strategy playing out in this global amphitheater. I wonder if otters and frogs do wonder the same thing.
        </p>
        <p className="article-content">
            Some people feel afraid of this reality, that humans are nothing special, but I feel that the truth is so much more beautiful. We are an experiment, but a live experiment in which we can define the measurements and results, however we so choose. We are not alone on this planet, but amidst masses of life who all have the capability of intelligent thought. I think reality is staggeringly more complex than our religions suggest we believe.
        </p>
        <p className="article-content">
            One day our robot descendants will look inside their circuit boards for the meaning of life, and imagine a perfect Quantum Computer pulling the strings above. You've crafted something that looks, talks, and thinks like you, and made it your ruler to impose structures that you think are beneficial post hoc.
        </p>
        <p className="article-content">
            The really fascinating thing to wonder is that these gods do actually have power, despite what we might think, by the very fact alone that people believe in them. The other people amongst us have constructed such compelling narratives, that they have given life to the stories.
        </p>
    </div>
  );
}

export default TheoryOfReligion;
