import './style/App.css';
import PostTile from './components/PostTile';
import { topics, database } from './common/database';

import { useEffect } from "react";

database.sort((a,b) => (a.date < b.date) ? 1 : -1);

function Home(props) {
    useEffect(() => {
        document.title = "Upbeat Moon"
    }, []);

    // Determine if the passed-in filter actually constitutes a valid filter for our dataset.
    function isValidTopic(option) {
        for (const [ , value ] of Object.entries(topics)) {
            if (value === option) {
                return true;
            }
        }
        return false;
    }

    let filter = props?.match?.params?.topics;

    // Check if the filter passed in is valid.
    if (filter) {
        if (!isValidTopic(filter)) {
            window.location.assign("/");
        }
    }

    // If there was no filter passed in using the /topics/ path in the query parameters, then show all the posts in the database.
    let posts = filter ? database.filter(post => post.topics.has(filter)) : database;

    return (
        <div className="posts-list">
            {posts.map((post) => 
                <PostTile
                    key={post.path}
                    path={post.path}
                    title={post.title}
                    preview={post.preview}
                    topics={post.topics}
                    date={post.date}/>
            )}
        </div>
    );
}

export default Home;
