import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function Career() {
  return (
    <div className="article-content">
      <p className="article-content">
        In the median case, jobs ultimately culminate in a financial transaction. In exchange for my time and services rendered, I am being paid some currency that gives me the freedom to move capital through the world markets or live out desired experiences. Currency can be monetary or equity, but it can also be intangibles like lifestyle, events, networks and access.
      </p>
      <p className="article-content">
        But a career is also more than that. Career is my day-to-day. It's the small steps I'm taking to create a body of work over a lifetime. It's 8 hours a day, 40 hours a week, 2000 hours a year, 80000 hours in a life. The work I do lets me stand independently, as well as gives me a sense of impact and satisfaction in life. But of course, my daily life doesn't quite fit this ideal mold.
      </p>
      <p className="article-content">
        Some of us find passion and meaning in our occupation, but most of us transact. This isn't a bad thing itself. This makes the world move. It also allocates people in the centers of what will generate the most capital. Unfortunately, this does not correlate to the things that humanity itself needs most. Money is an arbitrary devil. It has no morals, no inherent utility. It derives value from being desired. Humans want it because it signals powers, influence, and grants freedom.
      </p>
      <p className="article-content">
        There's this kind of weird alignment in society going on right now (probably for the last couple of centuries), where the jobs that are most materially beneficial are not necessarily good for society. The roles we attach the most status and wealth to, actually can cause harm to the greater public. Especially as an engineer, I'm oftentimes so motivated by the prospect of tinkering and playing and building, I can lose sight of the machine that I'm powering.
      </p>
      <p className="article-content">
        There is this term, called work-life balance. It's a little strange. Naturally, the work I do is inseparable from my life; it is a sub-component of my life. I cannot separate it. I can try to create hard divisions between the personal and professional, but they will constantly bleed into one another. It's important that whatever occupies my time at large, should at the least be improving my survival, and at the best, be exciting and meaningful. Because I will be thinking about it, mulling over work-related problems, even when I'm not explicitly working.
      </p>
      <h1 className="article-content">Priorities</h1>
      <p className="article-content">
        These questions really come down to what your life is building towards. We get one, as far as any of us are aware. Unless you subscribe to some ideas of unified consciousness, or reincarnation, this is the single shot that you get to live. There's a limited, fixed quantity of hours you have on the planet. Any hour, day, or decade of life spent, has an opportunity cost, a significant weight against all the other things that you could have been doing. So, your job matters, and the time you invest in what skills you learn matters.
      </p>
      <p className="article-content">
        These are the handles that I think go into deciding what career moves to make:
      </p>
      <ol className="article-content">
        <li className="article-content">Impact</li>
        <li className="article-content">Learning</li>
        <li className="article-content">People Network</li>
        <li className="article-content">Compensation</li>
        <li className="article-content">Location</li>
      </ol>
      <p className="article-content">
        I've presented this as an ordered list, because all of these pivots will have different weights. It won't be <b className="article-content">this</b> specific order for everyone, but there will be some order. All of these factors will flow and react with one another, inter-playing with each other in any given job. As you age, your priorities will shift over time, and these factors will rebalance, or potentially disappear altogether. Few jobs out there will have it all, but those will be your "dream job". You should know what you're measuring as you go on your career journey.
      </p>
      <h2 className="article-content">Impact</h2>
      <p className="article-content">
        This is how much your direct contribution to a problem helps promote a solution that affects other people. How much is your work directly able to help the team? How are your skills interacting with others'? How much agency do you have in the role? What is the result of the work that you are doing on the world as a whole?
      </p>
      <p className="article-content">
        You might be working at a company that has a really grand mission, but the role doesn't maximize usage of your skillset, and you end up less productive/effective than you could be. For example, imagine you are trained as an environmental engineer, but join the United Nations as a secretary. Perhaps the impact of the organization you are a part of is grand, but how are your skills specifically being utilized? Could you further optimize the work you're doing to take advantage of your capability?
      </p>
      <h2 className="article-content">Learning</h2>
      <p className="article-content">
        This is somewhat related to the previous point, but less about what you're giving to the role, and more about what you're taking from the role. While considering a job, you should ask yourself what kinds of challenges and skillsets you'd like to acquire. Does this role help you in gaining that expertise, or is it a step in the right direction?
      </p>
      <p className="article-content">
        I know this is hard to do, but I would advise everyone to not give a care about promotion, and give a lot of cares about learning, and cultivating a capability to build things. Money and reputation will follow. That is, if you can afford to do so, of course. Learning, either things that you find interesting, or you think are useful to society, can have a lot of compounding benefits over time, as compared to whatever minimal material (e.g., monetary) benefit you can attain at this point in time. This is especially true at the beginning of your career. Optimizing for learning can allow you to define the trajectory of your professional life.
      </p>
      <h2 className="article-content">People Network</h2>
      <p className="article-content">
        Who are the people you would be working with? What other kinds of people work at the company, and what is the culture like? This is really important, as you will spend a lot of time with these folks. If they are intelligent and collaborative people, you'll be able to spend your time in the role learning a lot and enjoying yourself. In addition to that, working in a place where you're able to build a strong professional network will allow you to benefit later on. As your former colleagues go on to other companies and start new opportunities, you can also move around and continue working with people you've enjoyed working with.
      </p>

      <h2 className="article-content">Compensation</h2>
      <p className="article-content">
        Money is important. At the end of the day, you need to pay your mortgage or your student loans or your credit card debt. Your living has a cost, and you need to make sure your bases are covered as far as that goes. Beyond that, it's really important to have a thorough understanding of your financial map (your living costs, your net worth targets, your future life plan). This will help inform what your minimum bar is. Whatever that minimum is, I don't personally feel like it makes too much sense to optimize beyond that. Money is just money, and it won't give you satisfaction.
      </p>
      <p className="article-content">
        Compensation isn't constrained to financial compensation, however. There are lifestyle perks a job might provide, like getting to travel, to go out to exclusive places and to meet interesting people. There is also, of course, retirement and healthcare to consider. This depends person-to-person, but again, you have to ensure that your needs are met. 
      </p>
      <h2 className="article-content">Location</h2>
      <p className="article-content">
        Location! Real estate agents like to claim it's everything. I've personally felt, in the past, that places aren't that important. Wherever you go, if your job is interesting and meets the other criteria, then location is a side consideration at best. But the truth is, that different cities have different characteristics; NYC has a bustle and a rich arts scene, Seattle is replete with activities in nature, Chicago neighborhoods have a vibrant community feeling. At the end of the day, that location is where you <b className="article-content">live</b>, and there's plenty of living to do outside of your work. So, if there are particular cities that pique your interest, note them down and have them known.
      </p>
      <h1 className="article-content">Phases</h1>
      <p className="article-content">
        Okay, so we've talked about how to measure the goodness of a job, but how does this all fit together over a lifetime? The way I see it, there are three dominant phases in my theoretical career, and I think they are generic enough to work for others as well:
      </p>
      <ol className="article-content">
        <li className="article-content">Earning + Learning (7 years)</li>
        <li className="article-content">Impact + Growth (15 years)</li>
        <li className="article-content">Giving Back (20 years)</li>
      </ol>
      <p className="article-content">
        This is my rough sketch of how I see my ideal progression unraveling. The time stamps are a little vague; they are more based on approximation than anything else. I expect that reality will reveal that these phases heavily overlap with one another, and coalesce in a blend of blurred lines. That being said, I'm currently in Phase 1.
      </p>
      <h2 className="article-content">Earning + Learning</h2>
      <p className="article-content">
        I happen to be lucky, and something I enjoy doing also happens to be profitable. It's not always so. Nonetheless, even if I enjoy being a software engineer, the problems that pay the most to solve are not always the most interesting or compelling. For the time being, I choose to optimize primarily for monetary gain and learning, until I hit a certain net worth. After that, I'll consider myself <InlineLink reference={new ReferenceLink("financially independent", "https://www.investopedia.com/terms/f/financial-independence-retire-early-fire.asp")}/>, free to pursue whatever fresh ink and perturbation in the world that catches my fancy, without financial benefit as even a factor.
      </p>
      <p className="article-content">
        I would recommend you to be thoughtful about how you spend the beginning of your career. If you're getting a phenomenal learning opportunity, by all means, go for it! Like I said earlier, learning will pay off in droves. Make sure to spend and save wisely during this period as well, to free yourself from financial constraints in career decision-making processes. Your older self will thank your youthful wisdom 👵🏾.
      </p>
      <h2 className="article-content">Impact + Growth</h2>
      <p className="article-content">
        I think this is the <b className="article-content">fun</b>, (albeit thoroughly exhausting), phase. Like I said earlier, there are pressing problems that need humanity to put its collective head together to solve. I'm particularly keen on diving into opportunities related to health, governance, and the environment, although lots of topics catch my fancy.
      </p>
      <p className="article-content">
        In this phase of career, my primary decision-making criteria will be around impact and ownership. I want to work on solving real-world, pressing issues that can benefit from the skillset I have spent the previous phase of life growing. I will continue to grow and refine that skillset, but now it would be in the context of problems I'm inherently passionate about, and in an environment where I feel agency to solve them. This is when I'll get to learn about scale, and about owning a solution while growing it from inception.
      </p>
      <p className="article-content">
        In the Impact phase, I should consider where my skills might be needed most, and begin the process of positioning myself into those areas. I'll figure out how and where I can contribute to maximize my impact. This time is dedicated to honing my craft, and cultivating the capability to build and lead, as appropriate.
      </p>
      <h2 className="article-content">Giving Back</h2>
      <p className="article-content">
        Eventually, the grand idea is that I should have attained enough experience and skills over these years that other people will find my insights valuable, so I can give back to help others develop their projects. At this phase, I want to be able to help in a diversified manner, and have a strong network of people who are working on valuable, worthy pursuits that are making a difference. By giving back to others who are hungry to solve compelling challenges, I hope to further reap the rewards of what I've learned over a career.
      </p>
      <p className="article-content">
        There is only one you, and this theoretical you has just spend over a decade dedicating themselves to some problems, and self-education. Now is the time where it pays off, where you get to pay it forward and impart your many learnings on the next generation of thinkers and doers.
      </p>
      <h1 className="article-content">Closing</h1>
      <p className="article-content">
        It's not lost on me that the idea of finding your work fulfilling, or seeking purpose in your occupation, is one that's typically afforded to those of privilege. What can I say to this, except that I wish for every person to have something exciting to wake up to, and to feel that they are making the world a better place. This can come from non-career outlets, like family, or adventure, or something else.
      </p>
      <p className="article-content">
        If you can't find something that makes you tickle, that's also okay. Just keep your ears pricked and your eyes open!
      </p>
    </div>
  );
}

export default Career;
