function TopicsBox(props) {
    return (
        <div className="topics">
            {[...props.topics].map((topic, idx) => {
                return (
                    <a href={"/topics/"+topic} key={idx} className="article-content topic" >
                        {topic}
                    </a>
                );
            })}
        </div>
    );
  }
  
export default TopicsBox;