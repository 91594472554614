import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function CiudadDeMexico() {
  return (
    <div className="article-content">
        <ol className="article-content">
            <li className="article-content">
                In the spring, the streets are lined with purple flowers.
            </li>
            <li className="article-content">
                The city is green, flush and bursting with verdant vegetation.
            </li>
            <li className="article-content">
                In proximity, <i className="article-content">pueblo mágicos</i> invite a sense of wonder and mysticism, with their bright colors and stark mountains.
            </li>
            <li className="article-content">
                The city government has setup <InlineLink reference={new ReferenceLink("public wifi access points", "https://internetparatodas.cdmx.gob.mx/puntos-wifi")}/> all over the municipality. I can get by okay without a data connection.
            </li>
            <li className="article-content">
                While living in Napoles, the government put on a public fair for well-being. Included stands to get information about vaccines, buy fresh veggies.
            </li>
            <li className="article-content">
                There are beautiful and dense mercados scattered everywhere in the city. These maze-like structures offer opportunities for fresh vegetables, cheap dining, butchers and cheeses. People are excited and helpful.
            </li>
            <li className="article-content">
                The <i className="article-content">paletas de agua</i> are so fresh, you can see the raw fruits mixed in with the ice. And they're still tasty, impeccably so.
            </li>
            <li className="article-content">
                The street food is a stellar mix of freshness and flavors. Tlacoyos, tacos, gorditas, flautas, elote. You have transient stands and food trucks with intense operations, cooking entire meals on the spot.
            </li>
            <li className="article-content">
                The rain here is real real, complete with rumbling thunder and lightning and angry, puffy clouds. Some people might tell you not to visit during the rainy season, but I say go. It's beautiful seeing the city flush in hydration.
            </li>
            <li className="article-content">
                The streets are colorful, so much so that they instill a sense of joy just in their visual appearance.
            </li>
            <li className="article-content">
                The buses have female/children-only cars. This bolsters my sense of safety traveling at night, especially because the buses seem to be really crowded most of the time.
            </li>
            <li className="article-content">
                Some parking lots have spots designated for pregnant women for quick access.
            </li>
            <li className="article-content">
                There are so many children. Happy, smiling children. Not just that, but it's very common to see dads with their young kids, which is actually pretty rare in my experience.
            </li>
            <li className="article-content">
                There's a vibrant, non-pretentious expat community that's enthusiastic about CDMX that frequently has social events.
            </li>
            <li className="article-content">
                People are kind, happy, and generous.
            </li>
            <li className="article-content">
                You see not just young people, but also old people out and about. You get multi-generational intersection in a city that is very community-oriented.
            </li>
            <li className="article-content">
                There's a strong sense of indigenous culture present in <InlineLink reference={new ReferenceLink("the way people dress", "https://web.archive.org/web/20230430195515/https://www.vogue.com/article/the-beautiful-stories-behind-8-of-oaxacas-traditional-outfits")} />, in the food they eat, and the decorations they use.
            </li>
            <li className="article-content">
                There are <InlineLink reference={new ReferenceLink("recycling stations", "https://www.biobox.com.mx/")} /> all over the city where you can drop in your plastic water bottles for a reward.
            </li>
            <li className="article-content">
                In car radios and street-side food stalls, you can hear that people still listen to their own local music that has its own distinct style and profile. In many parts of the world, song profiles have converged into this generic raggateon, pop-rap genre. But in Mexico, the culture of native sounds via Requinto guitars and mariachi are alive and well. Here's <InlineLink reference={new ReferenceLink("a song", "https://www.youtube.com/watch?v=mwNBa40y2oA")} /> that I heard that I really liked.
            </li>
            <li className="article-content">
                The city is distinctly not car-centric. It's extremely walkable. Grocery stores will be within pedestrian distance, and much of the city limits can be reached via a relatively robust public transit system. Both the buses and metro are frequent and comfortable, cheap and fast. The roads are generally small, and one-way streets are common. This makes it so that you're not fearing for your life every time you cross the road.
            </li>
        </ol>
        <p className="article-content">
            I truly can't recommend Mexico City enough, especially for Americans who are tired of the structure and lifestyle of American cities. Viva la CDMX.
        </p>
    </div>
  );
}

export default CiudadDeMexico;
