import '../style/App.css';

import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function CivilDisobedience() {
	return (
	<div className="article-content">
		<p className="article-content">
			Today is a day that honors Martin Luther King Jr., one of the foremost civic leaders in the history of the United States. We live in a society that has been made constantly more just over the centuries due to thoughtful, steadfast people putting their rational conscience ahead of the ease of assimilation.
		</p>
		<p className="article-content">
			They have looked at society, looked inside themselves, and found something that they wished to change. And in the face of vast adversity, they have moved forward with steadfast conviction to correct those differences. These ancestors of ours have helped to drive society in a direction so that every human being can be seen equal to all others, in order that the house and circumstances of our birth not define our realm of possibility or freedom.
		</p>
		<p className="article-content">
			Humanity is a grand experiment. It does not have inherent rules. Governance is a manufactured idea, and how beautiful at that - all of us humans working together to create a society that can support us all as a collective. It needs to be molded into a form that we the people deem right.
		</p>
		<p className="article-content">
			It is easy to get ahead of ourselves, and take for granted the many civil liberties that we are afforded. These aren't laws of nature, they are the laws of humankind, borne of theories of morality and ethics. We have to work on fulfilling the vision of righteousness, expend energy to bring dreams of justice to fruition. 
		</p>
		<p className="article-content">
			We're not there yet; the work is not nearly done. Even so, we should reflect upon and appreciate the leaders of change who have come before us, and build the courage to be a small force of good for the people who will come after us.
		</p>
		<p className="article-content">
			Here's a list of lesser-known, inspiring civic leaders who stood for what they felt was right:
		</p>
		<ul className="article-content">
			<li className='article-content'><InlineLink reference={new ReferenceLink("Iqbal Bano", "https://theprint.in/features/iqbal-bano-whose-voice-made-faizs-poem-hum-dekhenge-a-protest-anthem-for-all-time/405168/")}/>, who stood up to Zia-ul Haq, the military dictator in the 1980s in Pakistan. She stood up to the dictator through music, performing the iconic revolutionary song <InlineLink reference={new ReferenceLink("Hum Dekhenge", "https://www.youtube.com/watch?v=dxtgsq5oVy4")}/> to a stadium of 50000 people in Lahore as an act of civic defiance.</li>
			<li className='article-content'><InlineLink reference={new ReferenceLink("Frederick Douglass", "https://en.wikipedia.org/wiki/Frederick_Douglass")}/>, who learned how to read despite being enslaved in the early 1800s. He escaped slavery and subsequently published a trove of writings on the topic of slavery, as well as an autobiography, <InlineLink reference={new ReferenceLink("Narrative of the Life of Frederick Douglas, an American Slave", "https://en.wikipedia.org/wiki/Narrative_of_the_Life_of_Frederick_Douglass,_an_American_Slave")}/> which revolutionized the views that the public had towards people who were enslaved. In addition to being an outspoken abolitionist, he was also an adamant supporter of the women's suffrage movement.</li>
			<li className='article-content'><InlineLink reference={new ReferenceLink("Lyudmila Alexeyeva", "https://en.wikipedia.org/wiki/Lyudmila_Alexeyeva")}/>, who stood up as a political dissident to the Soviet government. She was a strong proponent of human rights and founded the Moscow Helsinki Group to monitor the Soviet adherence to the Heslinki Accords. Russia is a particularly tough environment for dissidents, but she found a way to make her voice heard. Here's a notable quote from her:
				<blockquote className="article-content">
					What would happen if citizens acted on the assumption that they have rights? If one person did it, he would become a martyr; if two people did it, they would be labeled an enemy organization; if thousands of people did it, the state would have to become less oppressive.
				</blockquote>
			</li>
			<li className='article-content'><InlineLink reference={new ReferenceLink("Alaa Abd El-Fattah", "https://www.eff.org/deeplinks/2022/01/eff-condemns-unjust-conviction-and-sentencing-activist-and-friend-alaa-fatttah")}/>, who has repeatedly exercised his right to free speech and fought for access to information for Egyptian citizens. His activism stands as a significant contribution to the fight against the Egyptian government's authoritarianism. Alaa is currently being held in prison, and has suffered from multiple bouts of detention. The reality is that, even in today's age of ease of access to information, many citizens around the world still have to struggle to voice their thoughts.
			</li>
			<li className='article-content'><InlineLink reference={new ReferenceLink("Sherif Gaber", "https://en.wikipedia.org/wiki/Sherif_Gaber")}/>, who is a significant political activist who has defended freedom of expression and religion in Egypt by openly critcizing religion on <InlineLink reference={new ReferenceLink("online platforms", "https://www.youtube.com/c/SherifGaber")}/>. He has been jailed for his commentary and persecuted by the government.</li>
		</ul>
	</div>
  );
}

export default CivilDisobedience;
