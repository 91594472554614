import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function WhyKhoj() {
  return (
    <div className="article-content">
        <p className="article-content">
            I recently left a job I loved at <InlineLink reference={new ReferenceLink("Probably Genetic", "https://www.probablygenetic.com/")}/> to start a company with my cofounder/friend, <InlineLink reference={new ReferenceLink("Debanjum", "https://taksati.xyz/")}/>. The core charter of our company, <InlineLink reference={new ReferenceLink("Khoj", "https://khoj.dev")}/>, is to make a <b className="article-content">trustworthy, deeply personal AI assistant that helps people be more effective.</b>
        </p>
        <p className="article-content">
            The idea for Khoj was borne from a simple truth: search, as we know it today, is lagging far behind what our technology has become capable of in the last decade. We have significantly advanced machines' understanding of natural language, which allows us to engage with the our computers in far more dynamic ways. Pair that in conjunction with how much of our data has been digitized, and <b className="article-content">we see a massive opportunity in improving the way people engage with their personal information</b>.
        </p>
        <p className="article-content">
            A lot of what software does revolves around its role as a communication layer between humans and machines. At its core, that's really what the internet offers: passage of information at fast speeds with computers and fiber optic cables as cargo ships. Traditionally, converting human-understood data to computer-understood data required understanding of various computing languages and intermediate layers. <InlineLink reference={new ReferenceLink("LLMs (large language models)", "https://en.wikipedia.org/wiki/Large_language_model")}/> help reduce that need. They allow us to communicate with the words we already know and extract insights with simpler interfaces.
        </p>
        <p className="article-content">
            We have to get a few things right at this critical inflection point.
            <ol className='article-content'>
                <li className='article-content'>
                    Ensure that these tools prioritize privacy and security. Many of these pipelines will be handling the most sensitive personal data from individuals' knowledge bases. People should always remain in control of their data access.
                </li>
                <li className='article-content'>
                    Develop with accessibility at the forefront of design. Don't gatekeep technologies or tools. Our advanced systems already suffer from widening equality gaps, and AI is only going to make this problem worse unless done thoughtfully.
                </li>
                <li className='article-content'>
                    Make the experience joyful and encourage more fulfilling human connection. Technology will never replace the value of humanity, and the tech we use should enable us to grow, learn, and think better to help one another.
                </li>
                <li className='article-content'>
                    Responsible use of AI should keep sources of truth close to the output. AI still isn't explainable, and our technologies should be designed in a way that is aware of those limitations.
                </li>
            </ol>
        </p>
        <p className="article-content">
            A lot of the doom talk that surrounds AI comes from a justifiable premise: that the deployment of these technologies can lead to foundational shifts in how society is operated, potentially to our detriment. I actually agree with this. Alignment is a hard problem, and AI is accelerating our <InlineLink reference={new ReferenceLink("Fourth Industrial Revolution", "https://www.weforum.org/agenda/2016/01/the-fourth-industrial-revolution-what-it-means-and-how-to-respond/")} /> in ways we can't predict or control.
        </p>
        <p className="article-content">
            But, I'm an optimist. I believe that there is massive upside to the way AI can affect our personal and societal capability. Recent breakthroughs can fundamentally bridge language barriers, they can make intellectual deficits easier to overcome, and they can reduce complexity in our everyday life just by making the world more explainable. They can also help us exponentially speed up drug discovery, unlock new speeds of computing, and solve hard energy problems.
        </p>
        <p className='article-content'>
            AI ethics committees are going to be critical in the next decade(s) in helping us usher in a more positive relationship with novel services, but I think discussion-oriented institutions are inherently limited. It's also important for us to start building products that promise to use AI responsibly and create observable systems around it (and I really like <InlineLink reference={new ReferenceLink("building things", "/devlove")}/>).
        </p>
        <p className='article-content'>
            What's fun about working on an AI assistant for everyday users is that I had the chance to work on enterprise assistance at Microsoft (see <InlineLink reference={new ReferenceLink("Cortana Briefing", "https://support.microsoft.com/en-us/topic/how-cortana-helps-you-in-briefing-email-from-microsoft-viva-478c90f6-a9b4-3c24-6a63-ff85426611a5")}/>), where we built a new assistance offering from scratch over two years. I enjoyed working with my team there, I loved our shared goal to make something that would delight users, and I learned so much from scaling a product to millions of DAU. But there's a fundamentally massive gap in what we're offering to everyday users and what we're offering to power, enterprise users.
        </p>
        <p className='article-content'>
            Power users are continuously being serviced with more bespoke technologies that help them with making better use of their time, but we're leaving huge portions of the population under-serviced. There's a massive global productivity gap, and we want to target that issue, because good assistance shouldn't be a privilege. One of the bad outcomes of AI availability would be that it asymmetrically benefits those who are already empowered by society, and leaves behind the rest.
        </p>
        <p className='article-content'>
            There will be a lot of executive assistant tools for C-suite folks and managers. We want to make something for the rest of us, the writer developing a first draft for her novel, the dad arranging his kids' karate classes, and the student trying to learn a new language. We want to make something that helps people be more effective, and we want to do it in a way that is accessible, joyful, and human.
        </p>
        <p className="article-content">
            We need to remember that there are humans at the center of all technological change. A lot of technology is designed in a way that seems to increasingly dehumanize us, reduces us to pixels in an avatar, to dings in a Discord server, or lead potentials in a LinkedIn message. With Khoj, I'm hoping to stretch the bounds further of what it means to be a happy, healthy human living an intellectually nourished life with our digital devices.
        </p>
    </div>
  );
}

export default WhyKhoj;
