import {useState} from "react"; 
import './style/App.css';

function Subscription() {
    const [emailAddress, setEmailAddress] = useState("");
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    function subscribe(e) {
        e.preventDefault();
        console.log(emailAddress);
        var bearer = 'Bearer ' + process.env.REACT_APP_SERVER_SECRET;
        fetch('https://server.upbeatmoon.com/subscribe/'+emailAddress, {
            method: 'POST',
            headers: {
              'Authorization': bearer,
              'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log(response)
            if (response.status === 200) {
                setIsSubscribed(true);
                setErrorMessage("");
            } else {
                setErrorMessage("Hmm, that didn't work...double check your email.");
            }
        });
    }

    return (
        <div className="about">
            <div className="about-header">
                <h2>subscribe below for updates on new posts!</h2>
                <p>i'll email very infrequently, personal guarantee.</p>
            </div>
            {
                isSubscribed
                ? 
                    <div>
                        Subscribed ✅
                    </div>
                :
                    <form className="subscribe">
                        <input
                            className="subscribe"
                            type="text"
                            onChange={e => setEmailAddress(e.target.value)}
                            placeholder="email@domain.com" />
                        <button
                            className="subscribe"
                            onClick={subscribe}
                            type="button">
                                Subscribe
                        </button>
                    </form>
            }
            {
                errorMessage &&
                <div>
                    {errorMessage}
                </div>
            }
        </div>
  );
}

export default Subscription;
