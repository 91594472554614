import '../style/App.css';

import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function Artashastra() {
	return (
	<div className="article-content">
		<p className="article-content">
			Kautilya (also known as Chanakya) was quite the kingmaker of his time. He constructed the chief political theory during the Ancient Mauryan Empire. It's estimated that this text, Artashastra, was written sometime around 200 BC - 0 AD. Artashastra is a complete political handbook, detailing the how, what, where, when, and why of a kingdom in those times. It remains surprisingly pertinent in some ways even today.
		</p>
		<p className="article-content">
			As with any political or legal text, it's important to remember that these are guidelines set out primarily by people who deal with the abstract, and theoretical aspects of government. They weren't necessarily representative of the actual day-to-day life of people. Nevertheless, political treatises by people who have power in society do generate a kind of lens into the kingdoms of their time. In a way, they are the ideal state of people who have the capability to implement their vision of the world. 
		</p>
		<h2 className="article-content">Economy</h2>
		<p className="article-content">
			Economic wellbeing was a cornerstone of a healthy kingdom. There were several arguments throughout the text for why economic development is necessary, stemming from the root assumption that without money, people will not be able to meet their basic necessities and desires. And if they are not able to meet their basic necessities and desires, rebellion and discontent will stir amongst their ranks. Below is a succinct summary which elucidates the importance of this point.
		</p>

		<blockquote className="article-content">
			It is the army which is dependent on finance. If not paid, [the soldiers] either go over to the enemy or kill the king. Finance is necessary to undertake any state endeavour and is the chief means for both dharma [righteous duty] and kama [enjoyment]. [However,] Kautilya adds that the relative importance of the army and the treasury may depend on the circumstances. The time, the place and the nature of the task determine which is more important. For, the army is [sometimes] the means of acquiring and protecting the treasury. But the treasury is [always] the means of acquiring wealth as well as the army. [On the whole,] the calamity to the treasury is more serious since it affects all others.
		</blockquote>
		<p className="article-content">
			I won't get into the dirty details here (because I found these sections exceptionally dry), but major highlights from the description of the Treasury and finances departments highlighted that economic activity was heavily regulated. The exact kind of product, the kind of activity, the quantity of output, was fully monitored by the state, for the purpose of taxation and regulation. What came in and what went out (export/import) was monitored closely. There was even a law that declared that "useless items" were not to be imported into the kingdom, which I found thoroughly amazing.
		</p>
		<p className="article-content">
			In the Kautilyan model, industry was intertwined with the state. The government closely undertook development and maintenance of various industries. Spurring the right kind of economic growth was important to the maintenance of political power and influence, which meant the state kept a close eye on the undertakings of people's businesses.
		</p>

		<h2 className="article-content">Hierarchical Decision-Making</h2>
		<p className="article-content">
			One thing that is quite neat about Kautilya's framing of dilemmae is that he provides a very clear flowchart of how problems should be approached, depending on the priority order. Generally, he comes up with a basic framework for what is important in society, and uses that framework to generate the responses to various thought experiments. For example, consider conflict-resoluton:
		</p>
		<blockquote className="article-content">
			[A well-known concept in Indian tradition is the set of four methods used in dealing with situations of potential or actual conflict: sama, dana, bheda and danda (i.e. adopting a conciliatory attitude, placating with rewards and gifts, sowing dissension among enemies and using force). 
		</blockquote>
		<blockquote className="article-content">
			It is easier to employ a method earlier in the order than a later one. Placating with gifts is twice as hard as conciliation, sowing dissension three times as hard and use of force four times.
		</blockquote>
		<blockquote className="article-content">
			In the case of a son, a brother or a kinsman, the appropriate methods are conciliation and placating with gifts. In the case of citizens of the city, the people of the countryside or the army, placating with gifts or sowing dissension among them are the right methods. In the case of neighbouring princes or forest chiefs, one should show dissension or use force. This order is anuloma [natural and, therefore, recommended]; if the methods are used in reverse order [dana before sama or danda before bheda] it is pratiloma. 
		</blockquote>
		<p className="article-content">
			Kautilya will devise a natural schema or ordering of how various abstract concepts should be organized relative to one another, and use that as a generative framework to streamline decision-making. It sounds simple, but this is actually incredibly useful for reducing cognitive load and making complex decisions simpler and faster.
		</p>


		<h2 className="article-content">Women</h2>
		<p className="article-content">
			One of the foremost takeaways from this text is that life for women was highly constrained in the past (big shocker, yeah?). It was explicitly decreed that women's primary purpose was as a reproductive vehicle, specifically for sons, in order to carry on the family name. I don't know how this structure of patriarchy became so stable across cultures, but time and again I've found common themes of general distrust of women and primary duty as becoming a mother to sons in ancient texts, including <InlineLink reference={new ReferenceLink("One Thousand and One Arabian Nights", "https://en.wikipedia.org/wiki/One_Thousand_and_One_Nights")} /> and <InlineLink reference={new ReferenceLink("Shahnameh", "https://en.wikipedia.org/wiki/Shahnameh")} />, by Firdausi. Women are cunning, seducers and liars, and must be controlled to beget sons. Once I unlock the underlying mechanism of why this system arises, I'll be sure to dissect it. Until then, feel free to indulge in a hearty feeling of so-glad-I-live-in-the-present:
		</p>
		<blockquote className="article-content">
			The four vices springing from excessive desire are addiction to hunting, gambling, women and drink. 
		</blockquote>
		<blockquote className="article-content">
			The role of women is succinctly stated by Kautilya as: ‘The aim of taking a wife is to beget sons’. From this principle of perpetuating the family line through sons, others follow. ‘The frustration of a woman’s fertile period is a violation of a sacred duty’. ‘A wife shall not conceal her fertile period and a husband shall not fail in his duty to try to get a son during his wife’s fertile period’. If a wife was barren for eight years or if she had borne only daughters over a twelve-year period, the husband could take a second wife without paying compensation to the first or returning her dowry. When a man had more than one wife, the earliest surviving wife or the one who had borne many sons was given priority.
		</blockquote>
		<p className="article-content">
			Pretty brutal hearing it put so explicitly, but at least Kautilya isn't filtering what he thinks. This does provide us a really astute view into how women were regarded in those times. 
		</p>
		<blockquote className="article-content">
			A wife shall not indulge in drinking or unseemly sports, if so prohibited [by her husband]. She shall not go [without her husband] on pleasure trips or to see performances, with other men or even with other women, either by day or by night. She shall not leave the house when the husband is asleep or intoxicated or refuse to open the door to her husband.
		</blockquote>
		<blockquote className="article-content">
			An imbalance in punishments has to be noted. The penalty for killing the madam of an establishment was three times the release price and that for killing a prostitute in her establishment or her mother or daughter was only the Highest Standard Penalty 2.27.17. On the other hand, if a prostitute killed a client, she was burnt or drowned alive
		</blockquote>

		<p className="article-content">
			Although, I do have to point out here that there is still an imbalance in how prostitutes and wealthy women are treated in society. At the very least, the profession was regulated by the state at that time and had protections in place for the women.
		</p>
		<blockquote className="article-content">
			Pregnancy carried with it some rights, mainly in the interests of protecting the child. Causing abortion was a serious crime, the punishment varying from the Lowest to the Highest Standard Penalty, depending on the means used. Procuring the abortion of a female slave also carried a punishment of the Lowest Standard Penalty. Women were not to be tortured during pregnancy or for a month after childbirth. For a woman convicted of murder, the sentence of death by drowning was carried out a month after childbirth. Pregnant women could use the ferries free of charge.
		</blockquote>
		<blockquote className="article-content">
			The underlying principle in the Arthashastra is that a woman was always dependent on, under the control of and subservient to, her father, her husband or her son. In fact, in the chapter on title to property, women are included in the list of property, along with deposits and pledges. A wife could not, without the permission of her husband, drink, indulge in unseemly sports or go on pleasure trips. She could not go to see performances, with other men or even with other women, either by day or at night. She could not leave the house when her husband was asleep or drunk; she could not refuse to open the door to him.
		</blockquote>
		<blockquote className="article-content">
			This preoccupation with bearing sons also gave women some rights. A girl, whose father was indifferent about her marriage for three years after her reaching puberty, could find herself a husband, even one from another varna. Likewise, a father lost his rights if he prevented his daughter’s husband from approaching her for seven periods. Among the reasons given for a wife’s right to refuse to have intercourse with her husband is that she had already borne him sons.
		</blockquote>
		<blockquote className="article-content">
			Many aspects of the protection accorded to women were based on the inclusion of women in the list of people who needed the protection of the state because they were helpless and easily exploited. It is a debatable point whether women, as a segment of society, would have needed this protection, if society and the legal system had not placed them from the beginning in an inferior position. 
		</blockquote>
		<p className="article-content">
			There were also strong opinions regarding what work was or wasn't appropriate for women. With that in mind, many women participated in the textile industry. Of course, there are still <InlineLink reference={new ReferenceLink("large gender gaps in labor participation divided along sector lines", "https://ourworldindata.org/female-labor-force-participation-key-facts")}/>, though they ostensibly seem to narrow in some wealthy countries.
		</p>

		<h2 className="article-content">The Kingdom</h2>
		<p className="article-content">
			The high level order of how society should work:
		</p>
		<blockquote className="article-content">
			The guiding principles which govern the Kautilyan theory of foreign policy are: (i) a king shall develop his state, i.e. augment its resources and power in order to enable him to embark on a campaign of conquest; (ii) the enemy shall be eliminated; (iii) those who help are friends; (iv) a prudent course shall always be adopted; (v) peace is to be preferred to war; and (vi) a king’s behaviour, in victory and in defeat, must be just. 
		</blockquote>
		<p className="article-content">
			In fact, in pursuit of building an efficient and conscientious state, the king's schedule was fully accounted for. Each of his 24 hours was allotted per day, and it was pre-planned what activities he would undertake. Even his sleep was measured and fixed. Friendly reminder that power breeds responsibility, which is a total work-life-balance hit.
		</p>
		<p className="article-content">
			One guiding principle of how society was arranged was that the king, in the end, should be subservient to his subjects. The king had to service his people, keep them happy, and if they weren't happy, figure out what was good for them in order to make them happy. Foremost, it was understood that a king without his people was nothing, and therefore the king had to create systems to stabilize, and elevate the lifestyle of his populace. Interestingly, that should mean that an adept power-hungry king and an adept benevolent king should be indistinguishable. 
		</p>

		<blockquote className="article-content">
			‘There cannot be a country without people and there is no kingdom without a country.’" 
		</blockquote>

		<blockquote className="article-content">
			Unprotected, the small fish will be swallowed up by the big fish. In the presence of a king maintaining just law, the weak can resist the powerful. 
		</blockquote>
		<p className="article-content">
			In selecting the service members of the kingdom's offices, like the ministers and other administrators, I found it interesting that Kautilya specifically called out that they shouldn't have random integrity tests that might lead them to transgress. Do not lead someone to sin if they would not otherwise. 
		</p>
		<blockquote className="article-content">
			‘There can be no greater crime or sin’, says Kautilya, ‘than making wicked impressions on an innocent mind. Just as a clean object is stained with whatever is smeared on it, so a prince, with a fresh mind, understands as the truth whatever is taught to him. Therefore, a prince should be taught what is true dharma and artha, not what is unrighteous and materially harmful.’
		</blockquote>
		<p className="article-content">
			It occurs to me that people, especially in a Kautliyan state, like to live in a very ordered, structured, and predictable environment. Especially for a ruler, it decreases the net entropy that one has to deal with. In this environment especially, where the king was generally in charge of spurring on industry and making investments, the people weren't as tasked with the evolution of the society as they are in a free market, which decentralizes innovation.
		</p>
		<p className="article-content">
			This will come as no surprise to most folks, but this ancient society was <b className="article-content">very structured</b>, meaning that the divisions between people of differing castes were hard and strong. Caste is hereditary. The basic ordering of the caste system follows this pattern: Brahmin -{">"} Kshatriya -{">"} Vashya -{">"} Sudra. The four castes are as described below:
		</p>
		<ul className="article-content">
			<li className="article-content">Brahmins are the priestly caste. Their day-to-day should include memorizing mantras and becoming wholly closer to gods. Any meaningful interactions that people want to have with their gods will have to be undertaken with the help of a Brahmin. They definitively accrued more rights than others in society, including free ferry rides, being allowed to go out at night, and not having to attend carnival parties in the neighborhood. Yup, others were mandated to attend their neighborhood festivals.</li>
			<li className="article-content">Kshatriyas are the warrior caste. Generally, it looks like the ruling class would also be composed of people in this caste. These are people of resolve, grit, and fierceness who will fight and battle with honor and courage and "protect life".</li>
			<li className="article-content">Vaishya are generally the business caste. These would be the ones undertaking any industrial operations and engaging in economic activity, including trade, agriculture and animal husbandry.</li>
			<li className="article-content">Sudra got the toughest deal in society. Their duties would generally be constrained to manual labor, like working in farms and cattle-rearing, or entertainment professions such as singing or acting.</li>
		</ul>
		<p className="article-content">
			There were also Aryans and ascetics, who seem to be somewhat outside of the caste paradigm. Aryans, light-skinned people entering from the Persian lands, seemed to generally be afforded a high status in this ancient Indian society. Ascetics were also regarded highly and given special privileges, which provides an interesting juxtaposition against modern systems, in which asceticism isn't quite glorified or respected. Although, there was a fair amount of distrust even then with ascetics, because spies commonly took on the ascetic aesthetic as a disguise. 
		</p>
		<p className="article-content">
			I know that the residual affects of these structured caste systems are still reverberating until this day, but I found it quite fascinating, almost eerie, to have a detailed document that prescribed certain occupations, lifestyles, and privileges, dependent on a person's birth order back then. In our current dominant thought paradigm of humanism and individualism, these notions seem archaic, and so it's an interesting glimpse into how different societal structures operated. I do think such ideas are in the subtext of how modern systems organize, but they are not nearly as codified. Today's racism is more tucked away into the seams of society, but back then it was in-your-face. At least today, many of us agree things should be different.
		</p>
		<p className="article-content">
			One last interesting point pertaining to society is the distrust of darkness. There's this age-old fear of the dark that is a significant driver of the arrangement of urban structures, seen today in the flurry of street lights that dot our modern cities in the night time. In the past, Kautilya had elucidated that people were not to go outside after dark, unless they had an extremely demanding reason, or they were holding a lantern.
		</p>

		<h2 className="article-content">Spies</h2>
		<p className="article-content">
			Either Kautilya was a distrustful man, or this was an exceptionally distrustful time. I don't know how aware the populace was of this fact, but there were spies fully integrated amongst their ranks to capture the public sentiment of the kingdom and sow seeds of dissension in rival kingdoms. Kings were in a constant subliminal game to either undercut their competitors, or assist their allies. Doesn't sound too foreign, actually.
		</p>
		<p className="article-content">
			Kautilya seemed to have an almost joyful indulgence in describing the possible deployment schemas of spies on rivals to the king. This kind of plot line is only found in Bollywood dramas these days. Here aggressor refers to someone who is interfering with the kingdom's objectives:
		</p>
		<blockquote className="article-content">
			An agent, in the guise of a merchant, shall pretend to be in love with an intimate maid of the aggressor’s favourite queen, shower wealth on the maid and then abandon her. Then, another agent in the guise of the first one’s assistant, shall recommend a third agent, disguised as an ascetic [expert in love potions]. The so-called expert shall give a potion to be rubbed on the body of the object of desire. When this is done [with the help of the attendant-agent] and the merchant-agent ‘miraculously’ returns to the maid. The same potion shall be recommended to the queen to enable her to retain the love of the aggressor king. Poison shall be substituted and the aggressor killed. 
		</blockquote>
		<p className="article-content">
			I'm not joking, Kautilya had about a half dozen or so examples like this of how aggressive figures could be subdued. But the thing that stands out to me is that these schemes have a lot of flourish and a lot of room for unintended randomness. Any number of things in this idealize storyline could go amiss, rendering the whole position worse off than before. The agents could be found out, the wrong target could be hit, none of the expected emotions may arise. But maybe these were the eye-catching plot lines, and Kautilya left his really adept ideas unpublished.
		</p>
		
		<h2 className="article-content">Closing</h2>
		<p className="article-content">
			It's pretty interesting how there are quite a few parallels between society as it was 2000 years ago, and as it is today. The distrust amongst states, which was prevalent then, is still a core part of how large nation-states organize. We still deploy spies kind of foolishly, in overly complex plans, who end up making way more of a mess than there was originally. We still have intense hierarchies in our socioeconomic structures, albeit less structural than they were then. And of course, we view economic output as the backbone of our nation. There are notable differences between now and then in how much important is given to the individual vs. the collective, and how much privilege is given to people and families with religious credibility.
		</p>
		<p className="article-content">
			But time and again, when I read old texts like this one, it's impressed upon me that being a woman was pretty rough until a few decades ago. We have come leagues forward in getting more equality and freedom between the sexes. Of course, things aren't perfect on the gender equality spectrum, but the ticker of progress tends positively.
		</p>
		<p className="article-content">
			Kautilya as a statesman had prowess and dignity. He was able to construct orderly rules which informed how complex systems should be organized, and consolidated problems and people into groups that made them easier to manage. I find it quite notable that the society that Kautilya proposed generally strove to make life better for its subjects (aside from the obvious difficulties for women). He provides a very relevant orientation for anyone trying to create an organization which operates effectively and efficiently.
		</p>
	</div>
  );
}

export default Artashastra;
