import TopicsBox from "./TopicsBox";

import {
    Link
} from "react-router-dom";

function PostTile(props) {
  return (
    <div className="post-tile">
        <Link to={props.path} className="post-tile">
            <h1 className="post-tile">{props.title}</h1>
            <h3 className="post-tile">{props.date.toLocaleDateString("en-US")}</h3>
            <p className="post-tile">{props.preview}</p>
        </Link>
        <TopicsBox topics={props.topics} />
    </div>
  );
}

export default PostTile;
