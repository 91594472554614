import '../style/App.css';
import '../components/article/ReferenceBox';
import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function EconomicallyVegetarian() {

    return (
        <div className="article-content">
            <p className="article-content">
                Does adopting a vegetarian diet actually confer benefit to the environment?
            </p>
            <p className="article-content">
                I've been eating a pescatarian diet for the last four years. Admittedly, I've introduced free-range chicken back into the mix. I mostly don't miss anything from the before-times when I ate all kinds of meat, but occasionally I do get a whiff of a delicious charred piece of beef, or haleem, and some instinctual oratory memory kicks in.
            </p>
            <p className="article-content">
                Digressions aside, I've held fast to this idea that my sacrifice in this life, to abstain from contributing to the economic demand of meat products, is going to benefit the planet (the other life and creatures we co-habit with)* and our descendants. The environmental and ethical impacts of the methods used to meet the demands of meat <InlineLink reference={new ReferenceLink("are well documented", "https://en.wikipedia.org/wiki/Environmental_impact_of_meat_production")} />. Encaged chickens, constrained cows, diseased fish farms. Cow methane clouds up our atmosphere as it contributes to a potential runaway greenhouse gas effect. Grazing land for cattle paves the way to mass deforestation, especially in the Amazon, which would really come in handy for divesting all that CO<sub>2</sub> that these operations are producing. If only people could see, that if we get away from the dedicated meat production and into sustainable vegetable farming, it is possible to scale food production for the scale of our society. 
            </p>
            <p className="article-content">
                But, people don't really work like that. An individual farmer or a land owner isn't thinking about climate change when he's going about his life. He's just thinking about how he can send his kids to school, or save enough for a new house, and so on. For better or worse, we are not a hive mind species. Everyone's in it to find out what's better for themselves. We get locked into a prisoner's dilemma kind of situation, where any one defector can corrupt the gains the rest of the individuals in the ecosystem have by cooperating. Sometimes it pays to be selfish, when you're the only hawk amongst doves. 
            </p>
            <p className="article-content">
                Humans aren't quite designed for species-level coordination. We are bad at making sacrifices for a long term, intangible good, especially for people we don't know. <InlineLink reference={new ReferenceLink("This SlateStarCodex article", "https://slatestarcodex.com/2014/07/30/meditations-on-moloch/")} /> investigates this idea more thoroughly. Still, modern technology has done wonders in levelling up our cooperative capability vis-à-vis the Internet and other gadgets. We have created structures around us, bubbling up hierarchical structures, that seek to take advantage of some centralized decision-making. That's why policy is important. Policy gets to try and realign incentive structures. When solar panels came out, they were at first so expensive that it was impractical for regular people to actually make the investment to install them. But our centralized decision-making body was able to <InlineLink reference={new ReferenceLink("provide tax credits and rebates", "https://www.energysage.com/solar/benefits-of-solar/solar-incentives/")} /> to encourage people to make the jump sooner. This heightened the individual's personal benefit, and more broadly, their community's. And now we, or some of our neighbors, have solar panels on our homes.
            </p>
            <p className="article-content">
                Without policy-level shifts, even if meat demand dried up and disappeared overnight, there would only be a new agricultural practice that would replace it that would equally ravage our natural resources. It's not a matter of the meat industry itself, it's a matter of the greed of our capital structures to take advantage of ever-increasing food demand, and consumer desires for more convenient, available options. The person eating the beef burger doesn't want the Amazon Rainforest to be cut down, but they want a tasty, satisfying meal. It's hard for us to understand that these things are correlated, and shifting consumer demands will not be enough to change the production paradigm. Having a focus on providing vegetarian and vegan-friendly options is amazing, I do agree. It reduces the focal point of production on the meat industry to sustain human consumption habits. But, does it actually solve the food availability question? Again, the problem is not the material being produced itself, but the way it's being produced.
            </p>
            <p className="article-content">
                The veganism movement and all its offshoots have the inklings of grassroots movements, but they currently lack the critical mass or centralization to effect change the way the civil rights or abolitionist movements were capable of. Perhaps our supply chain systems are too nebulous to feel the effect of such small movements. But at the rate that eating veggies is affecting economic change, we may not expect to see beef decommissioned any time soon.
            </p>
            <p className="article-content">
                This zoomed-in example (vegetarianism as activism) serves as a proxy to many of the movements that are taking place across the world of citizens concerned abut the planet's wellbeing. People are carrying tote bags and shunning plastic, they're opting for bikes rather than cars, they're purchasing fair trade, sustainable cotton tees and thrifting. It's great that people are individually becoming more aware and educating themselves! I love the signals that the shift in consumer demand is reverberating through retail industries. But what we really <b>need</b> is the stepping in of a centralized body, and for industries to claim corporate responsibility. The scale of one person pales in comparison to the scale of one company. The average American emits 16 tons of CO<sub>2</sub> per year (globally super high), while <InlineLink reference={new ReferenceLink("ExxonMobil casually emitted 120 million metric tons in 2019", "https://www.statista.com/statistics/531354/greenhouse-gas-emissions-from-exxon-mobil-worldwide/")} />.
            </p>
            <p className="article-content">
                These thoughts are specifically meant to target the ethical-economic arguments made around vegetarian-activist models, but it's also true that reducing intake of meat in general, and especially red meats, has positive health effects, such as <InlineLink reference={new ReferenceLink("reducing risk of heart diseases and cancers", "https://www.medicalnewstoday.com/articles/87490")} />. But some say live happy, die young. I get it. Still, I wonder what the net effect on health outcomes and environmental outcomes would be if activists (or policy makers!) approached this line of appeal. After all, people can be motivated by self-interest, but it's hard to sacrifice for the benefit of the commons.
            </p>
            <p className="article-content">
                I don't believe eating meat itself is unethical. Maybe future generations will look at me as a monster for saying so, but there is a natural order on this planet that allows for certain predators to eat other living beings. I do think life should not suffer at our behest, but I would permit a graceful death. The question becomes a bit fuzzy as the variable of intelligence is introduced. Somehow, killing more intelligent animals feels more incorrect. As if we have taken something more special from the planet.
            </p>
            <p className="article-content">
                Ethics of killing animals themselves aside, the impact the meat and dairy industry have on the planet is undeniable. Something will have to change to make production more sustainable, whether that's scaling down or popularizing synthetic meats. To get there, some centralizing efforts are required. Otherwise, maybe our species will be the first to pave way for cows' grazing lands and our own extinction in one fell swoop 😅️. Anyway, til then, I'll keep opting for the tofu burger.
            </p>
            <p className="article-content footnote">
                * The planet doesn't actually care about what we do to it. Mother Earth will live and spin on, whether humans and animals wander her many acres or not.
            </p>
        </div>
    );
}

export default EconomicallyVegetarian;
