import ArticleHeader from "./ArticleHeader";
import { SupplementalReferenceBox } from "./ReferenceBox";

function Article(props) {
    return (
        <div className="article-content">
            <ArticleHeader title={props.title} topics={props.topics} date={props.date} />
            <props.body />
            <SupplementalReferenceBox references={props.references} />
        </div>
    );
  }
  
export default Article;