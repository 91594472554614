import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function MentalWellness() {
  return (
    <div className="article-content">
        <h2 className="article-content">My Frenetic Pathway</h2>
        <p className="article-content">
            I've dealt with mental health issues for most of my life. At varying stages, it's been better or worse. When I was 13, my lovely English teacher saw intentional scars on my arms and triggered a seemingly standardized public school response to inform my parents of the findings and schedule routine sessions with the school guidance counselor. These sessions got me through some really difficult years. The counselor affirmed that what I was experiencing was depression, and she offered me a depth of help through talk therapy which gave me the tools to better deal with the issues I was experiencing in adolescence. Especially for young minds, a lifeline like that can change the trajectory of your whole life.
        </p>
        <p className="article-content">
            I was lucky that the school stepped in to help me, and lucky that they had hired amazing staff who were capable of mitigating the issues I was dealing with. I wish that access to such resources wasn't a matter of luck. In the South Asian cultural context I'd grown up in, mental health issues are considered so taboo, that individuals will forgo seeking help for their conditions in order to maintain a specific image in front of their extended community. The fear of "having a label" outweighs the necessity of getting treatment for an illness of the mind. As such, <InlineLink reference={new ReferenceLink("untreated mental health issues are rampant in these communities and people are suffering in silence", "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5643212/")}/>. Mental health treatment needs to be de-stigmatized so people can feel empowered to seek out care for themselves or their loved ones.
        </p>
        <p className="article-content">
            Over the years, I've used various unhealthy outlets. Food, self-harm, escapism, drug use, and risky social behavior have all variably acted as mediums for my issues at different points. Now, in my mid-20s, I'm in a much more stable place. There are ups and downs, but the trajectory is far more predictable. It took me a really long time to realize that the issues I had were inside my own head. No matter what I did to outrun them, they could always catch up with me because they were like suitcases I was taking with me everywhere I went.
        </p>
        <p className="article-content">
            I've realized that when my emotions are out of control, it can be like there's a fog covering my reasoning. It prevents me from seeing through the obscurity to get to reality. Small issues are exacerbated. Large issues are insurmountable. The effort of addressing any of my many responsibilities is unconscionable. I eat poorly, I don't exercise, I under-perform at work. These things feed into each other and create a negative feedback loop, and it can be difficult to break out of it. But it's important to remember the fog will pass.
        </p>
        <h2 className="article-content">Journey into the Mind</h2>
        <p className="article-content">
            Meditation is the act of focusing your mind to enter into a state of calm and clarity.
        </p>
        <p className="article-content">
            I haven't quite cracked the code to happiness, but I have realized that it comes from inside, not from outside. No matter how many flights you take, how beautiful your house, how fine the wine or grand the mountain, that feeling of satisfaction will always be derived internally. It's your own brain that manifests it. In an ideal state, it should be capable of generating that feeling, regardless of your external circumstance.
        </p>
        <p className="article-content">
            That's where meditation has helped me. It moves the locus of motivation and understanding to the mind itself, to the direct source. I don't need a flight, a book, or a god to save me. Focusing in on what I can control, diving into the inner space of my mind, helps rebalance whatever outside factors are affecting me at that moment. You learn to detach yourself from the specific thoughts, the specific feelings, the specific manifestation of what's bothering you. You let the emotions wash over you, knowing yourself as an agent in a complex system. 
        </p>
        <p className="article-content">
            Nevertheless, it can be daunting to approach it alone. I've found these resources helped me quite a bit in my adulthood:
        </p>
        <ul className="article-content">
            <li className="article-content" >
                <InlineLink reference={new ReferenceLink("Headspace", "https://www.headspace.com/") }/>: This mobile app provides high-quality guided meditation sessions. They have both general courses and courses catered to specific issues (e.g., Break-Ups, Stress, Gratitude).
            </li>
            <li className="article-content" >
                <InlineLink reference={new ReferenceLink("TheraminTrees", "https://www.youtube.com/c/TheraminTrees")} />: This YouTube channel creates really high-quality content pertinent to the issues encountered when leaving religion.
            </li>
            <li className="article-content" >
                Stable Routines: Having a fixed routine is really important for when you enter the slump. The routine should be well-oiled and in motion in your normal day-to-day, so that you can run on autopilot when you're in the slump. <InlineLink reference={new ReferenceLink("My Morning Routine", "https://mymorningroutine.com/routines/")} /> captures a couple of morning routines of interesting people. I developed <InlineLink reference={new ReferenceLink("a personal application", "https://github.com/sabaimran/mylogs")} /> to help me track my daily activities and habits to manage my routine.
            </li>
        </ul>
        <p className="article-content">
            Tangentially, fasting is also a useful tool. A full day's fast can help hit reset on various issues. The exercise of self-control can help draw a clarity of the mind and make you more cognizant of your physical limitations. I recommend a water fast.
        </p>
        <p className="article-content">
            Talk therapy works for a lot of people. I haven't tried in adulthood, but I know many people tout its benefits. This can be a bit hard to access for people who are in a low service area or don't have the disposable income for sessions.
        </p>
        <h2 className="article-content">Be Aware of Your Needs</h2>
        <p className="article-content">
            Be aware of your body. At the most reduced level, depression can be represented as a neurochemical imbalance. Your mind is fighting itself, and part of the battle is to look at the field and realize that the state of conflict is temporary, and feelings are subjective. Feelings are a data point, but they are not the whole picture. Be an onlooker; accept that good and bad are not quantitative judgments. Most things just exist. Learn to come from a place <InlineLink reference={new ReferenceLink("of acceptance and understanding", "https://nav.al/happiness")}/>.
        </p>
        <p className="article-content">
            Our bodies are vessels. They are matter strung together in such a way that it can experience the world and make more intelligent decisions regarding existence. <InlineLink reference={new ReferenceLink("Consciousness arose as a better strategy for survival and procreation", "https://www.youtube.com/watch?v=H6u0VBqNBQ8")}/>, but we're hijacking it for tangential purposes. All of this byproduct of thought and consciousness has had implications that we don't fully comprehend as of yet, but we do know that we are always subject to the limitations of the physical spaces we exist in, especially that of our bodies. We are bound by what our bodies can do, and how they perceive. The act of meditation helps me to better make sense of that reality.
        </p>
        <p className="article-content">
            Most of us understand very well the importance of physical health. It's high time we started nurturing the mind as well. We are constantly unraveling the mysteries of how the brain works, and finding new ways to dialogue about mental health. These are some of the axes to consider in your overall mental health:
        </p>
        <table className="article-content">
            <tr className="article-content">
                <th className="article-content">
                    Axis
                </th>
                <th className="article-content">
                    Vectors
                </th>
                <th className="article-content">
                    Framing
                </th>
            </tr>
            <tr className="article-content">
                <td className="article-content">
                    Financial
                </td>
                <td className="article-content">
                    Am I able to pay the bills? Am I living paycheck-to-paycheck? How can I remedy my financial health and get myself into a more stable situation?
                </td>
                <td className="article-content">
                    When your basic financial health is in a state of uncertainty, it's very hard to make time for other things or exist with any peace of mind. Time will be spent worrying, stressing about necessities like food, bills, family, and otherwise. Stability in this realm is critical to emotional well-being.
                </td>
            </tr>
            <tr className="article-content">
                <td className="article-content">
                    Physical
                </td>
                <td className="article-content">
                    Am I eating well? Am I sleeping enough? Am I able to perform the tasks I want to? Am I getting daily exercise and sunlight? 
                </td>
                <td className="article-content">
                    A healthy body is a requirement to a healthy brain, in most cases. Physical movement helps to elevate our mood and benefits our overall outlook. Being more physically capable increases our feelings of confidence and efficacy.
                </td>
            </tr>
            <tr className="article-content">
                <td className="article-content">
                    Social
                </td>
                <td className="article-content">
                    How strong is my social system? Are there people in my life I can rely on? Am I making time for others?
                </td>
                <td className="article-content">
                    Humans are, by nature, very social animals. We have built up societies and rich traditions on top of very tight, complex social systems. The vast majority of us will derive more energy from being in a loving and supportive environment, and it's important to invest time in that.
                </td>
            </tr>
            <tr className="article-content">
                <td className="article-content">
                    Independence
                </td>
                <td className="article-content">
                    Am I able to make decisions for myself? Am I able to live my life the way I want to? Am I prevented from pursuing my goals and dreams?
                </td>
                <td className="article-content">
                    When our basic drivers and motivation is culled by changeable factors in our life, our spirit can be heavily damaged. On the other hand, when we have a feeling of self-efficacy, and the support to pursue what gives us joy, we can feel more enriched. Being able to live life in alignment with your values is a cornerstone of mental well-being.
                </td>
            </tr>
            <tr className="article-content">
                <td className="article-content">
                    Motivators
                </td>
                <td className="article-content">
                    What am I excited about? What am I working towards? What's my vision for the future, and how can I work towards it? 
                </td>
                <td className="article-content">
                    It's important to be working towards something in life. When we have a greater vision that we are moving towards, we can amplify that internal locus of motivation and achieve a sense of purpose.
                </td>
            </tr>
            <tr className="article-content">
                <td className="article-content">
                    Externalities
                </td>
                <td className="article-content">
                    Am I PMSing? Is a stressful deliverable coming up? Is there a major life event around the corner? Am I bringing my work home?
                </td>
                <td className="article-content">
                    Check in with yourself. Often times, these outside factors seep into our mind-space and affect our well-being, leaking into other areas along the way. Most of us are elevating our baseline stress by taking on undue burden at work, at home, or otherwise. Figure out how you can offload or manage it. Understand your biological cycles and how they affect your mood.
                </td>
            </tr>
        </table>
        <p className="article-content">
            Don't be ashamed of struggling. It's perfectly normal. Build up structures around yourself to help you get through the tough times, and seek help. Even if you have nothing, but you are content with yourself, you are wealthier than the whole world combined.
        </p>
    </div>
  );
}

export default MentalWellness;
