import '../style/App.css';

import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function DoingGoodBetter() {
	return (
	<div className="article-content">
		<h2 className='article-content'>Background</h2>
		<p className="article-content">
			<InlineLink reference={new ReferenceLink("Doing Good Better", "https://www.effectivealtruism.org/doing-good-better")}/> by William MacAskill is an analysis of how people can improve their impact-oriented outcomes in doing good based on measurable metrics. There are five key questions that help define whether a given strategy is effective.
		</p>
		<ol className='article-content'>
			<li className='article-content'>
				How many people benefit, and by how much?
				<ul className='article-content'>
					<li className='article-content'>
						How do you measure impact? <InlineLink reference={new ReferenceLink("QALY (quality-adjusted life year)", "https://en.wikipedia.org/wiki/Quality-adjusted_life_year")}/>. This measurement captures how much quantitative benefit someone receives based on a specific intervention.
					</li>
				</ul>
			</li>
			<li className='article-content'>
				Is this the most effective thing you can do?
				<ul className='article-content'>
					<li className='article-content'>
						Could resources be better used elsewhere? Not all solutions are equally valuable. We sometimes have to take hard tradeoffs in how to help people, because it's never going to be possible to equally distribute resources to all people in all places. The world is full of different ecosystems for different human beings, and the value of a dollar is not the same in all these places.
					</li>
				</ul>
			</li>
			<li className='article-content'>
				Is this area neglected?
				<ul className='article-content'>
					<li className='article-content'>
						Are there enough people working on this problem? There are a lot of problems in the world that receive gratuitious attention, but these are not always the most worthy of our time. Especially when natural disasters happen, people will flood a lot of money towards a specific, one-off event, though that event in no way diminishes the ongoing issues that are carrying on in the rest of the world. Disaster mitigation is usually handled appropriately by international and governmental organizations, and our per dollar impact usually isn't significant.
					</li>
				</ul>
			</li>
			<li className='article-content'>
				What would have happened otherwise?
				<ul className='article-content'>
					<li className='article-content'>
						You have to have a concrete idea of what would happen if this specific intervention was not in place. This can help us understand better where our resources may be best allocated. Many problems are over-allocated, and would not particularly benefit from our marginal attention.
					</li>
					<li className='article-content'>
						There's an argument made for the side of keeping a high-earning job which doesn't have a particularly good impact, in exchange for donating the majority of the income earned. This allows people to live in their current status in life, while still maximizing additions of QALYs.
					</li>
					<li className='article-content'>
						In some analyses, this argument may be a cop-out. In some areas, the framing is relevant, but it can also be dangerous because it makes people tend towards inaction or accept injustice. It's the <InlineLink reference={new ReferenceLink("crowd paradox", "https://en.wikipedia.org/wiki/Bystander_effect")}/>.
					</li>
				</ul>
			</li>
			<li className='article-content'>
				What are the chances of success, and how good would success be?
				<ul className='article-content'>
					<li className='article-content'>
						Differing activities can have a varying degree of success and corresponding reward. We think about this in terms of expected value:	
						<math className='article-content'>
							<mrow className='article-content'>
								<mo className='article-content'>E</mo>
								<mo className='article-content'>[</mo>
								<mi className='article-content'>gain from action</mi>
								<mo className='article-content'>]</mo>
								<mo className='article-content'>&#x2243;</mo>
								<msub className='article-content'>
									<mo className='article-content'>x</mo>
									<mn className='article-content'>s</mn>
								</msub>
								<msub className='article-content'>
									<mo className='article-content'>p</mo>
									<mn className='article-content'>s</mn>
								</msub>
								<mo className='article-content'>+</mo>
								<msub className='article-content'>
									<mo className='article-content'>x</mo>
									<mn className='article-content'>f</mn>
								</msub>
								<msub className='article-content'>
									<mo className='article-content'>p</mo>
									<mn className='article-content'>f</mn>
								</msub>
							</mrow>
						</math>
						, where <math><mrow className='article-content'>s</mrow></math> denotes success, <math><mrow className='article-content'>f</mrow></math> denotes failure, and <math><mrow className='article-content'>x</mrow></math> is the cost/benefit of each outcome, <math><mrow className='article-content'>p</mrow></math> is the probability of each outcome.
						<ul className='article-content'>
							<li className='article-content'>
								This comparison should also take into account the expenditure of resources in any given action. For example, voting can be a fairly low effort activity that yields a high degree of benefit if successful. This framework is useful in a number of questions.
							</li>
						</ul>
					</li>
					<li className='article-content'>
						What charity should you donate to?
						<ul className='article-content'>
							<li className='article-content'>
								What does the charity do? How cost-effective is each program area? How robust is <b className="article-content">evidence</b> behind each program? How well is each program implemented? Does the charity need additional funds?
							</li>
						</ul>
					</li>
					<li className='article-content'>
						What career should you pursue?
						<ul className='article-content'>
							<li className='article-content'>
								How do I personally fit with this job? What's my impact while I'm working this job? How does this job contribute to my impact later on in life?
							</li>
						</ul>
					</li>
					<li className='article-content'>
						Which cause should you focus on?
						<ul className='article-content'>
							<li className='article-content'>
								Scale: How large is this problem and how many lives does it affect in the long run?
							</li>
						</ul>
						<ul className='article-content'>
							<li className='article-content'>
								Tractability: Given the state of affairs, how possible is it to address this problem? Are there already existing problems, or solutions in place within this cause?
							</li>
						</ul>
						<ul className='article-content'>
							<li className='article-content'>
								Underserved: How much attention is given to the problem?
							</li>
						</ul>
					</li>
				</ul>
			</li>
		</ol>
		<h2 className='article-content'>Shortcomings</h2>
		<h3 className='article-content'>Does earn-to-give make sense?</h3>
		<p className='article-content'>
			A limitation of the earn-to-give model is that it doesn't justly compensate for the supposed harm that a given job might be contributing through you. Assuming that you are maximizing your salary, one can assume that there will be a direct relationship between that compensation gain and associated impact. As such, you will be contributing more to whatever shared objective the specific team you've joined has. In computing your actual net benefit in QALY, you'd have to assess the cost of the role as well. In the starkest example, taking a million dollar job at a defense contractor and donating all your money is not exactly better than just working an ordinary job and donating all your money. The defense contractor role may be easier to quantify, but there are others like software engineer, hedge fund analyst, that may be trickier in the broader scope of their role.
		</p>
		<h3 className='article-content'>Limitations to quantitative analysis of interventions</h3>
		<p className='article-content'>
			Focusing on the current lowest-hanging fruit will drive resources in the direction of an easy-to-solve problem. This is great. But one counter point I have is that this neglects to understand how we can better approach systemic solutions. It's certainly a step in the right direction, but it also leads my mind to wonder how we can quantify the merits of investing in solutions that would have more far-reaching consequences. Perhaps the issue is that such solutions are not well sought-out. Nevertheless, moonshots are often worth funding.
		</p>
		<p className='article-content'>
			The argument for sweatshops is dangerous because many things which offer a lesser harm can be justified in this way. Using this same argument, I could justify "benevolent" slave labor because I'll treat people well and it will help the greater society as a whole. A useful takeaway from Tony Ord's book <InlineLink reference={new ReferenceLink("The Precipice", "https://en.wikipedia.org/wiki/The_Precipice:_Existential_Risk_and_the_Future_of_Humanity")}/>, is that humanity's rate of development has far outpaced our rate of wisdom.
		</p>
		<h3 className='article-content'>Better alternatives to philanthropy</h3>
		<p className='article-content'>
			MacAskill doesn't address a fundamental cornerstone of uplifting people out of poverty: building sustainable businesses. <InlineLink reference={new ReferenceLink("Acumen", "https://acumen.org/")}/> is a fantastic organization that works on exactly that - helping people build businesses that also improve life outcomes. I also wonder whether the entire concept of philanthropy is misguided. If we want stable, sustainable systems for people to support themselves and grow economically and health-wise, we need to come up with more comprehensive methods for uplifting them. Philanthropy allows temporary improvement, dependent on cash infusion.
		</p>
		<h2 className='article-content'>Overall</h2>
		<p className='article-content'>
			<i className='article-content'>Doing Good Better</i> provides really useful, generalized frameworks for evaluating how we can be more effective in our shared goal for improving outcomes for humanity. It makes the fantastic leap of moving us towards applying data-driven principles, which are common in industry, to the social sector. While I think it's a great spring board, I'm keen to see how we can incorporate more holistic, systemic approaches to change and address the root causes of observed issues.
		</p>
	</div>
  );
}

export default DoingGoodBetter;
