import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function TimeBroker() {
  return (
    <div className="article-content">
        <p className="article-content">
            Don't read this unless you have the 7 minutes for it.
        </p>
        <p className="article-content">
            The mind beckons in many directions. In our modern attention economy, it's critical to nurture one's ability to focus. There are constantly any number of things that are demanding that we touch, look at, buy them. The dings, buzzes, screens jumping and fluctuating are yearning to be acknowledged. The vast majority of these are just hostile distractions. We need to be vigilant over what exactly is entering our mind-space, what is worth paying attention to, and what is just noise.
        </p>
        <p className="article-content">
            One might waffle a phrase about multitasking, but this doesn't pass the sniff test. We don't actually have the ability to multitask or load balance multiple processes in our head. Our brains basically have one CPU that runs all of the tasks, and every context switch has a fair amount of cost. Granted the brain does some complex background processing in the subconscious, but we don't have control over that, so it doesn't count. If we're haphazard about our time, whatever is newest or most available to the psyche will take precedence, and the hard, long problems will fall to the wayside, either constantly being rescheduled or disappearing altogether. Whatever can get a piece of your time and drag it onwards, will do so. There's not cost to the other party, only benefit. We have to take ownership and be our own time brokers.
        </p>
        <p className="article-content">
            Moreover, if we look at the quality of output, whatever you're producing will generally have a more positive outcome if you have dedicated stretches of focus time to work on it. Focus is paramount in seeing outcomes through. X hours split amongst 2 projects, or X hours given wholly to 1 project can be the difference between 2 incomplete projects and 1 completed project. Generally, we want to go for completion, because that yields something tangible and satisfying and iterable.
        </p>
        <p className="article-content">
            To prevent randomness from creeping into my headspace, I generally maintain notifications off for all apps, unless there is a strong reason to do otherwise. I've followed this general rule for several years, and it's been wonderful on helping me go deeper into projects and maintaining more present, meaningful relationships. With aggressive filtering, you do run the high risk of being out of the social loop, unawares of the latest trends and fancies that are memetically propagating through society and your social circle. It's up to you to decide the importance of that against the importance of the purposefulness of your time.
        </p>
        <p className="article-content">
            The only currency that can never be returned to you is time. Time is critical - it's a zero sum game. I didn't totally understand this in my Microeconomics class back in high school, but now I feel it viscerally: <b className="article-content">there really is no free lunch</b>. We have become used to thinking of money as the common denomination in which exchanges take place, of course because it makes transactions incredibly convenient, but it is a layer of abstraction between what you're actually exchanging and what you're actually getting. In a workplace, your effort, your time rendered, that is being bought, and in exchange the cash is allowing you to buy someone else's effort, their time rendered. Now that the experiment of the attention economy is unraveling itself full throttle in society, your attention is being taken in exchange for, memes, videos, social updates from peers. You get a temporary hit, a small distraction from your routine, and the service gets a bit of your attention, and more data about how to gather more of it.
        </p>
        <p className="article-content">
            When it comes to task management, I keep detailed notes of the things I need (or want) to do using an <InlineLink reference={new ReferenceLink("Org file in Emacs", "https://orgmode.org/")} />. I've found that externalizing the list of my tasks greatly reduces the mental burden of managing these work items, and helps to prevent items from slipping through the cracks. And this includes maintenance not just of the small trivialities (like groceries, paying rent), but also the larger, long scale milestones that span decades (like marriage, purpose). It helps to set the direction for my life with intention.
        </p>
        <p className="article-content">
            Whatever it is that you do, whatever you spend time on, every second of every day, that's being etched into the append-only log that reflects back your life, the Cannon of You. What do you want it to say? And for anything that wants your time, ask yourself whether it is worthy.
        </p>
        <p className="article-content footnote">
            The irony of this post is that immediately after I drafted it, I had this urge to write the backend for subscriptions for the blog and sank the next 8 hours trying to figure out how to get my server running on Elastic Beanstalk. But! I was perfectly aware of where my time was going! I wouldn't say I was in control, but awareness is the first step :).
        </p>
    </div>
  );
}

export default TimeBroker;
