import { ReferenceLink, InlineLink } from '../components/article/ReferenceBox';

function SailingMombasa() {
  return (
    <div className="article-content">
        <p className="article-content">
            Sailing with Captain Frank and Captain Deso this time. Captain Frank perpetually sports a baseball cap, and smiles widely with bright, tobacco-tinted teeth. Captain Desu has long dreads that descend down his back. His eyes are sharp, analytical. Last time, it was only Captain Desu and we didn't get much sailing in due to the low tide and exposed coral in the shallow waters. We kept hitting the reefs and having to maneuver the boat out, which isn't as easy as it sounds. You have to tilt the boat over to minimize the amount of friction against the rough under-surface, and the boat is heavy.
        </p>
        <p className="article-content">
            The sunlight is incredible on the water, and the water itself a crystal clear light blue. Beneath the turning waves, you can see mounds of rock, algae, coral, and even some mysterious sea life like starfish and sea urchins and unnamed fish.
        </p>
        <p className="article-content">
            The boat is a sailboat, perhaps informally called a dhow. It has a single large sail, rigged to a large stalk of bamboo. It's secured with ropes bound through openings in the cloth at regular intervals. All the boat's skeleton can be easily seen as fabricated out of wood; there's not a piece of metal in sight. At the back, there are pegs and poles used to secure a rudder and pivots to hold the sail. On the flank of the boat, the word Nusra shines alongside colors of red, green, and yellow paint. The waving, yellow sail sports a flying horse jumping over the moon with the words "gone to the moon, be back soon."
        </p>
        <p className="article-content">
            Captain Frank and Captain Desu are joyful, relaxed sailors. They delay instructions until necessary. You can feel that their sailing knowledge comes from an internally derived place. Sailing is just something they know. While I'm coming from a place of theory, understanding that the sail must hit the wind at this certain angle in order to catch speed and expending compute, they are moving with the boat as an extension of themselves.
        </p>
        <p className="article-content">
            Sometimes the boat tips to the side and water gushes in. Our captains laugh. We hit a big rock head-on, and they share a mischievous smile. The rudder came off and nearly fell in the water, and Captain Desu cut a piece of rope with a rust-crusted pocket knife and rigged it back on.
        </p>
        <p className="article-content">
            We talk of the election. Captain Desu proclaims proudly that he's never voted. The politicians just take their money and do nothing. Captain Frank has a more measured approach. He says it's important to vote, so the politicians also feel that they have to do something for you.
        </p>
        <p className="article-content">
            Captain Desu had gnarly gashes on his feet. He'd stubbed one toe on a rock, multiple times. In another spot, he'd been cooking on the sailboat using charcoal when some bumpy waves hit. The charcoal flew off, onto his foot, searing off a circle a half-inch in diameter. Both wounds were exposed, and neither were properly healing due to the constant salt water exposure.
        </p>
        <p className="article-content">
            They told me about Covid, how all of Mombasa's beaches were shut down for a year. The beach is a source of livelihood for much of the population. They weren't even allowed to go out to the water for fishing. In fear of getting caught, fishermen instead were heading out with spears to fish with their bare hands during sundown. Times were tough, and families had to operate lean.
        </p>
        <p className="article-content">
            One of the days we'd gone sailing, we heard from Captain Frank that a small fishing boat had capsized the night before. 18 men had drowned, while 1 safely swam to shore, tears streaming down his face. Captain Frank was working on a rescue operation to go and retrieve the bodies and debris after the accident. He said that many people are desperate for money and food, and sometimes go to dangerous lengths in order to obtain it.
        </p>
        <p className="article-content">
            I see a hustle here. There's a lot of energy, a lot of hunger to do something. This place is in a transitory time, as much of these economies are being forcefully propelled into an Information Age that lives online, that many of the business operators are unprepared for.
        </p>
        <p className="article-content">
            Sailing is a reprieve. The basic mechanism behind sailing is that you are using the sail to guide the wind from the front of the boat to the back of the boat. As the wind is pushed back, a force is created that propels the boat forward to balance out. You use the rudder and the lines on the sail to maintain this relationship as needed. Getting to a destination with a sailboat is not a straightforward task, contrary to what your eighth grade geometry teachers may have told you. It requires a bit of back and forth (tacking and gybing), depending on the direction of the wind.
        </p>
        <p className="article-content">
            You feel like you're going significantly faster when you're upwind. This is when the wind is blowing in your face and your hair is whipping back. When you go downwind, the atmosphere is mostly calm and smooth. There's a whole field of math behind knots, and for good reason. Knots are complicated. Experienced hands work with muscle memory. If you try to ask a knot-tier to explain their knot, they'll be at a loss. You can only ask them to show you. You can see <InlineLink reference={new ReferenceLink("animated knots", "https://www.animatedknots.com/")}/> here. 
        </p>
        <p className="article-content">
            We've gotten to meet so many warm and energetic people here in Kenya. People have a friendliness that is open and inviting, and they're excited to engage with you. They're supportive of each other, and the community atmosphere is extremely strong. I find this is something largely missing in the culture of places I've been in the US. While the standard of living is great and all, there is very little community-oriented mindset. People are hyper-individualistic. But alas, the grass is always greener on the other side.
        </p>
    </div>
  );
}

export default SailingMombasa;
