function Bonfire() {
    return (
        <div className="article-content">
            <h2 className='article-content'>How to make a bonfire</h2>
            <p className="article-content">
                You need three ingredients.
            </p>
            <p className="article-content">
                It's important that AI development take place in the open, and freely, in order to protect against a few things:
                <ol className='article-content'>
                    <li className='article-content'>
                        Tinder: stuff that catches quickly and burns fast. (leaves, paper)
                    </li>
                    <li className='article-content'>
                        Kindling: stuff that catches a little more slowly and takes a little longer to burn. (twigs, branches)
                    </li>
                    <li className='article-content'>
                        Kindling: stuff that catches a little more slowly and takes a little longer to burn. (twigs, branches)
                    </li>
                </ol>
            </p>
            <p className="article-content">
                To setup a good bonfire, the initial structure you layout is pretty important. Doing the pre-work can help you avoid doing a bunch of shenanigans later on to get your fire going.
            </p>
            <p className="article-content">
                The principle you want to follow for you initial structure is that it should have interconnected logs while leaving space for the passage of O2. The tinder and kindling laying the groundwork for the logs to heat up and create a self-sustaining fire. Fires will die out either because they run out of oxygen or because they didn't get hot enough to stay lit. The tinder will give way to heating the kindling, and the kindling will give way to heating the firewood.
            </p>
            <p className="article-content">
                You light your tinder, kindling first with your lighter. You can use some lighter fluid to get the reaction going faster, but in theory you shouldn't need it if you follow these principles ^.
            </p>
            <p className="article-content">
                I know of two good structures at least.
            </p>
            <h2 className='article-content'>The Pyramid</h2>
            <img src="./pyramid.png" alt="pyramid" className="article-image" />
            <p className='article-content'>
                Line up three logs in a pyramid structure, allowing them all to heat at the base and let the fire travel up. They'll establish a really hot middle, helping sustain the reaction.
            </p>
            <h2 className='article-content'>
                The Log Cabin
            </h2>
            <img src="./log_cabin.png" alt="pyramid" className="article-image" />
            <p className='article-content'>
                Every two horizontal logs are followed by two vertical logs on the x-z axes. I start with one stack, and keep adding layers as the fire burns on.
            </p>
            <p className='article-content'>
                I really like fire. We should have more bonfires. But remember to burn responsibly. Always completely douse the embers of your fire when you're done with the pit for the night, and never bonfire when fire danger is high.
            </p>
        </div>
    );
}

export default Bonfire;
